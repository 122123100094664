import { getUrlParameter } from '/helpers/url';

LemonFrog.UsersCountInArea = (function () {
  const minUsersCount = 50;

  function getUsersCountInArea(locationData) {
    if ( !onWizardPage() ) return;

    //search area is a radius setted in km
    let SEARCH_AREA = 10;
    $.ajax({
      type: 'GET',
      url: '/api/v2/users/count_in_area',
      data: { distance: SEARCH_AREA, place: locationData, role: getUrlParameter('role') },
      async: false,
      dataType: 'json',
      success: function (data) {
        showUsersCountInArea(data['users_count'], data['users_role']);
      }
    });
  }

  function onWizardPage() {
    return window.location.pathname.includes("wizard/profile");
  }

  function showUsersCountInArea(usersCount, usersRole) {
    if (notEnoughUsers(usersCount)) return;
    $('.js-users-in-area-count').removeClass('hidden')
    $('#js-user-count').text(usersCount + ' ' + usersRole)

    $('.js-postal-code').on('input', function() {
      hideUsersCount();
    });
  }

  function hideUsersCount() {
    $('.js-users-in-area-count').addClass('hidden');
  }

  function notEnoughUsers(usersCount) {
    return Number(usersCount) < minUsersCount;
  }

  return {
    getUsersCountInArea: getUsersCountInArea
  }
}());

