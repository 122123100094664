LemonFrog.RecaptchaRenderer = (function () {
  let recaptchaRendered = false;

  function renderRecaptcha () {
    if (recaptchaRendered) return;
    recaptchaRendered = true;

    $('.g-recaptcha').each(function () {
      let $form = $(this).closest('form');
      let $button = $('[type=submit]', $form);

      let widgetId = grecaptcha.render(this, {
        'sitekey': recaptchaSiteKey,
        'callback': function (token) {
          /// This part fixes the Problem with supporting many widgets on one page
          $('.g-recaptcha-response').val(token);
          $form.off('submit');
          $form.submit();
        }
      });
      $button.attr('data-widget-id', widgetId);
      $button.on('click', function (evt) {
        let $this = $(this);
        widgetId = $this.attr('data-widget-id');
        $form = $this.closest('form');

        evt.preventDefault();

        $form.parsley()
             .whenValidate()
             .done(function() {
               grecaptcha.execute(widgetId);
             })
      });
    });
  };

  function recaptchaCallback () {
    if ( recaptchaFormAbsentOnPage() ) return;
    renderRecaptcha();
  }

  function recaptchaFormAbsentOnPage() {
    return $('.g-recaptcha').length == 0;
  }

  return {
    recaptchaCallback: recaptchaCallback,
    renderRecaptcha: renderRecaptcha
  };
}());

window.recaptchaCallback = function () {
  LemonFrog.RecaptchaRenderer.recaptchaCallback();
}
