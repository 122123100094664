LemonFrog.Payments = LemonFrog.Payments || {};

LemonFrog.Payments.FormSubsection = (function () {
  const moduleName = 'Payments.FormSubsection',
    subsectionToDoIconSel = '.js-subsection-to-do-icon',
    subsectionSuccessIconSel = '.js-subsection-success-icon';

  function updateStatusIcon(moduleSel, done) {
    if (done) {
      $(moduleSel).find(subsectionToDoIconSel).hide();
      $(moduleSel).find(subsectionSuccessIconSel).show();
    } else {
      $(moduleSel).find(subsectionToDoIconSel).show();
      $(moduleSel).find(subsectionSuccessIconSel).hide();
    }
  }

  return {
    moduleName: moduleName,
    updateStatusIcon: updateStatusIcon,
  }
}());
