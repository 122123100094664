$.effects.define( "shift", "toggle", function( options, done ) {
  let element = $( this ),
      show = options.mode === "show",
      from = options.from || "top",
      shift = parseInt(options.shift) || 16,
      initialMargin = parseInt(element.css(`margin-${from}`)),
      initialCSS = {},
      resultingCSS = {};

  if ( show ) {
    initialCSS = { "opacity": 0, [`margin-${from}`]: `${initialMargin - shift}px` };
    resultingCSS = { "opacity": 1, [`margin-${from}`]: `${initialMargin}px` };
  } else {
    initialCSS = { "opacity": 1, [`margin-${from}`]: `${initialMargin}px` };
    resultingCSS = { "opacity": 0, [`margin-${from}`]: `${initialMargin - shift}px` };
  }

  element.css(initialCSS);
  element.animate(resultingCSS, {
    queue: false,
    duration: options.duration,
    easing: options.easing,
    complete: done
  });
});
