LemonFrog.DatePickerComponent = (function () {
  const moduleName = 'DatePickerComponent';

  function init() {
    $('.js-submit-mobile-date').on('click', setMobileDate);
  }

  function setMobileDate() {
    const $dialog = $(this).parents('.js-mobile-date-dialog'),
      $dialogDatepicker = $dialog.find('.js-datepicker'),
      formattedDate = $.datepicker.formatDate(
        $dialogDatepicker.datepicker( "option", "dateFormat" ),
        $dialogDatepicker.datepicker("getDate")
      ),
      $input = $(`[data-open-dialog="#${$dialog.attr('id')}"`).first();

    $input.val(formattedDate).trigger('input');
    LemonFrog.Dialog.dismissDialog(`#${$dialog.attr('id')}`);
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.DatePickerComponent);
