LemonFrog.Payments = LemonFrog.Payments || {};

LemonFrog.Payments.PaymentMethods = (function () {
  const moduleName = 'Payments.PaymentMethods',
    paymentBoxesSel = '.js-payment-methods-box',
    paymentBoxCheckboxesSel = '.js-payment-methods-box-checkbox',
    paymentDescriptionBoxesSel = '.js-payment-methods-description-box',
    paymentSubmitButtonSel = '.js-payment-methods-submit',
    paymentSubmitDescriptionSel = '.js-payment-methods-submit-description',
    paymentMethodsBillingAddressBoxSel = '.js-payment-methods-billing-address-box',
    paymentBoxActiveClass = 'payment-method__box--active';

  function init() {
    initPageState();
    initPaymentBoxesTrigger();
    initPaymentCheckboxesSelection();
  }

  function initPageState() {
    $(paymentBoxCheckboxesSel).each(function () {
      assignPaymentMethodToGoogleAnalytics(this);

      if ($(this).is(':checked')) {
        let $currentPaymentBox = $(this).closest(paymentBoxesSel);
        $currentPaymentBox.addClass(paymentBoxActiveClass);

        let $currentDescriptionBox =
          $(this).closest(paymentDescriptionBoxesSel);
        updatePaymentMethodsInterface($(this), $currentDescriptionBox);
      }
    });
  }

  function initPaymentBoxesTrigger() {
    $(paymentBoxesSel).on('click', function () {
      let $currentPaymentBox = $(this);
      let $currentDescriptionBox =
          $(this).find(paymentDescriptionBoxesSel);
      let $selectedCheckbox = $(this).find(paymentBoxCheckboxesSel);

      $(this).find(paymentBoxCheckboxesSel)[0].click();
      $(paymentBoxesSel).removeClass(paymentBoxActiveClass);
      $currentPaymentBox.addClass(paymentBoxActiveClass);
      $(paymentDescriptionBoxesSel).hide();

      updatePaymentMethodsInterface($selectedCheckbox, $currentDescriptionBox);
    });
  }

  function initPaymentCheckboxesSelection() {
    $(paymentBoxCheckboxesSel).on('change', function (event) {
      assignPaymentMethodToGoogleAnalytics(event.target);
    });
  }

  function assignPaymentMethodToGoogleAnalytics(element) {
    let $form = $(element).closest('form');
    let label = ''
    if ($form.find('.js-payment-methods-box-checkbox:checked').val()) {
      label = $form.find('.js-payment-methods-box-checkbox:checked').data('analytics')['label'];
    }
    $form.attr('data-analytics-label', label);
  }

  function updatePaymentMethodsInterface(selectedCheckbox, currentDescriptionBox) {
    currentDescriptionBox.show();
    $(paymentMethodsBillingAddressBoxSel).show();
    $(paymentSubmitButtonSel).val(selectedCheckbox.data('button'));
    $(paymentSubmitButtonSel).data('disable-with', selectedCheckbox.data('button'));
    $(paymentSubmitDescriptionSel).html(selectedCheckbox.data('description'));
  }

  return {
    moduleName: moduleName,
    init: init,
  }
}());

LemonFrog.initModule(LemonFrog.Payments.PaymentMethods);
