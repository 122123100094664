$(document).ready(function () {
  const observer = new MutationObserver(function(entries) {
    let modules = $(entries[0].target).find('[data-module]').map((_, elem) => {
      return $(elem).data('module')
    });

    modules.each((_, name) => {
      LemonFrog.initModule(LemonFrog[name])
    })

  });

  $('[data-observable]').each((_, elem) => {
    observer.observe(elem, { childList: true, subtree: true })
  })
})
