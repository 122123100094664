import { getBreakpoint } from '/helpers/ui';

LemonFrog.InputField = (function() {
  const moduleName = 'InputField';
  const moduleRef = `[data-module="${moduleName}"]`;
  const activeLabelScale = 0.75;
  const activeLabelSidePaddingPx = 8;

  function init(inputNode) {
    if ($(inputNode).data('counter')) initCounter(inputNode);

    blurOnScroll();

    if (hasValue(inputNode)) initActions(inputNode);

    if ($(inputNode).filter(':visible').length > 0) initDimensions(inputNode);

    if ($(inputNode).find('.input-field__control').attr('autofocus'))
      focusOnLoad(inputNode)
  }

  function initDimensions(inputNode) {
    if ($(inputNode).find('textarea.js-textarea-autosize').length > 0)
      adjustInputHeight(inputNode);

    const realLabel = $(inputNode).find('.input-field__label');
    if (realLabel.length == 0) return;

    const innerPrepend = $(inputNode).find('.inner.prepend');
    const innerPrependWidth = innerPrepend.length ? innerPrepend.outerWidth(true) : 0;
    realLabel.attr("style", `--left-after-animation:-${innerPrependWidth}px;`);
  }

  function initControlFlowEvents() {
    $(document).on('change', '.new-styling.input-field__control', function() {
      const parentField = $(this).parents('.new-styling.input-field');
      if (!hasLabel(parentField)) return;
      if (this.value) {
        activateLabel(parentField)
      } else if (!hasPlaceholder(parentField)) {
        deactivateLabel(parentField)
      }
    });

    $(document).on('focus', '.new-styling.input-field__control', function() {
      const parentField = $(this).parents('.new-styling.input-field');

      parentField.addClass('activated');

      if (hasLabel(parentField)) activateLabel(parentField);
    });

    $(document).on('blur', '.new-styling.input-field__control', function() {
      const parentField = $(this).parents('.new-styling.input-field');

      parentField.removeClass('activated');

      if (!hasValue(parentField) && !hasPlaceholder(parentField) && hasLabel(parentField))
        deactivateLabel(parentField);
    });

    $(document).on('click', '.new-styling.input-field__box', function() {
      $(this).find('.new-styling.input-field__control').focus();
    });

    $(document).on('click', '.new-styling.input-field__control:not(.propagate)', function(e) {
      e.stopPropagation();
    });

    $(document).on('click', '.new-styling.input-field__extra-content', function(e) {
      if ($(e.target).hasClass('propagate') || $(e.target).parents('.propagate').length) return;

      e.stopPropagation()
    });

    $(document).on('input', '.js-textarea-autosize', function() {
      adjustInputHeight($(this).parents('.new-styling.input-field'));
    });
  }

  function initActionHelpersEvents() {
    $(document).on('click', '.new-styling.input-field__action', function() {
      $(this).addClass('clicked').stop(true).delay(200).queue(function() {
        $(this).removeClass('clicked').dequeue();
      })
    });

    $(document).on('input', '.new-styling.input-field__control', function(e) {
      const parentField = $(this).parents('.new-styling.input-field');
      const parentFieldActions = parentField.find('.new-styling.input-field__action:not(.js-fixed-action)');

      if (this.value.length > 0)
        parentFieldActions.fadeIn(200);
      else
        parentFieldActions.fadeOut(200);
    });

    $(document).on('click', '.js-reveal-password', function() {
      const parentField = $(this).parents('.new-styling.input-field');
      const control = parentField.find('.new-styling.input-field__control');

      if (control.attr('type') === 'password') {
        control.attr('type', 'text');
        parentField.find('.js-icon-when-password-visible').removeClass('hidden');
        parentField.find('.js-icon-when-password-hidden').addClass('hidden');
      } else if (control.attr('type') === 'text') {
        control.attr('type', 'password')
        parentField.find('.js-icon-when-password-visible').addClass('hidden');
        parentField.find('.js-icon-when-password-hidden').removeClass('hidden');
      }
    });

    $(document).on('click', '.js-clear-input', function() {
      $(this).parents('.new-styling.input-field').find('.new-styling.input-field__control').val('').focus().trigger('input');
    });
  }

  function focusOnLoad(inputNode) {
    activateLabel(inputNode);
    $(inputNode).find('.input-field__control').focus();
  }

  function hasValue(inputNode) {
    return $(inputNode).find('.new-styling.input-field__control')[0].value.length > 0;
  }

  function hasPlaceholder(inputNode) {
    return $(inputNode).find('.new-styling.input-field__control')[0].placeholder.length > 0;
  }

  function hasLabel(inputNode) {
    return $(inputNode).find('.new-styling.input-field__label').length > 0;
  }

  function adjustInputHeight(inputNode) {
    const $control = $(inputNode).find('.new-styling.input-field__control');
    const height = $control[0].scrollHeight;

    $control.css({
      'overflow': 'hidden',
      'height': 'auto'
    }).css({
      'overflow': '',
      'height': height
    })
  }

  function activateLabel(inputNode) {
    $(inputNode).find('.input-field__label, .input-field__outline-label')
                .addClass('activated');
  }

  function deactivateLabel(inputNode) {
    $(inputNode).find('.input-field__label, .input-field__outline-label')
                .removeClass('activated');
  }

  function initCounter(inputNode) {
    const control = $(inputNode).find('.new-styling.input-field__control');
    const counterLimit = $(inputNode).data('counter-limit');
    const counterElement = $.parseHTML('<div class="new-styling input-field__counter"></div>');

    $(counterElement).append(`<span class="js-characters-count">${control[0].value.length}</div>`);

    if (counterLimit) $(counterElement).append(` / <span class="js-counter-limit">${counterLimit}</span>`);

    $(inputNode).find('.new-styling.input-field__details').append(counterElement);

    $(inputNode).find('.new-styling.input-field__control').on('input', function() {
      $(counterElement).find('.js-characters-count').html(this.value.length);
    });
  }

  function blurOnScroll() {
    if (!['xs', 'sm'].includes(getBreakpoint())) return;

    $(window).on('scroll touchmove', function() {
      if ($(document.activeElement).is("[data-blur-on-scroll]")) {
        document.activeElement.blur();
      }
    });
  }

  function initActions(inputNode) {
    $(inputNode).find('.new-styling.input-field__action:not(.js-fixed-action)').show()
  }

  return {
    init: init,
    initDimensions: initDimensions,
    initControlFlowEvents: initControlFlowEvents,
    initActionHelpersEvents: initActionHelpersEvents
  }
}());

$(document).ready(function() {
  LemonFrog.InputField.initControlFlowEvents();
  LemonFrog.InputField.initActionHelpersEvents();

  $('.new-styling.input-field').each(function() {
    LemonFrog.InputField.init(this);
  });
});
