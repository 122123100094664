import { getBreakpoint } from '/helpers/ui';

LemonFrog.PaymentsTableComponent = (function () {
  const moduleName = 'PaymentsTableComponent';
  const table = ".js-payments-table";
  const tableRow = ".js-payments-table-row";
  const seeAllButton = ".js-see-all-payments";
  const seeLessButton = ".js-see-less-payments";
  const swipeIcon = ".js-swipe-icon";

  function init() {
    initTableSize();
    initButtonToggle();
  }

  function initTableSize() {
    if ($(table)[0].classList.contains("short-table")) {
      setShortTableSize();
      $(seeLessButton).hide();
    }
  }

  function initButtonToggle() {
    $(seeAllButton).click(function() {
      animatedWholeTableSize();
      $(seeAllButton).hide()
      $(seeLessButton).show()
    })

    $(seeLessButton).click(function() {
      animatedShortTableSize();
      $(seeLessButton).hide()
      $(seeAllButton).show()
    })
  }

  function setShortTableSize() {
    if (["sm", "xs"].includes(getBreakpoint())) {
      const shortTableHeight = $(tableRow).outerHeight() * 7
      $(table).height(shortTableHeight);
    }
  }

  function animatedShortTableSize() {
    if (["sm", "xs"].includes(getBreakpoint())) {
      const shortTableHeight = $(tableRow).outerHeight() * 7
      $(table).animate({
        height: shortTableHeight
      }, 400);
    }
  }

  function animatedWholeTableSize() {
    let currentHeight = $(table).height();
    $(table).css("height", "auto");
    let fullHeight = $(table).height();

    $(table).height(currentHeight).animate({
      height: fullHeight
    }, 400);
  }



  return {
    init: init,
    moduleName: moduleName
  };
}());

LemonFrog.initModule(LemonFrog.PaymentsTableComponent);
