import { antiflicker } from './antiflicker';

class Stepper {
  constructor(el, { event, loop, onSwitch, canProceed }) {
    this.$el = $(el);
    this.event = event || "click";
    this.loop = loop || false;
    this.onSwitchCallback = onSwitch;
    this.canProceed = canProceed || (() => true);

    this.$steps = this.$el.find(".js-stepper-step");
    this.$currStep = this.$steps.first();
    this.$prevStep = this._findPrevStep();
    this.$nextStep = this._findNextStep();

    this.$steps.hide();
    this.$currStep.show();

    this.$el.on("stepper-forward", $.proxy(this.onForward, this));
    this.$el.on("stepper-backward", $.proxy(this.onBackward, this));
    this.$el.find(".js-stepper-forward").on(this.event, $.proxy(this.onForward, this));
    this.$el.find(".js-stepper-backward").on(this.event, $.proxy(this.onBackward, this));
  }

  destroy() {
    this.$el.off("stepper-forward", this.onForward);
    this.$el.off("stepper-backward", this.onBackward);
    this.$el.find(".js-stepper-forward").off(this.event, this.onForward);
    this.$el.find(".js-stepper-backward").off(this.event, this.onBackward);
  }

  onSwitch(from, to, direction, skip) {
    this.$currStep = to;
    this.$nextStep = this._findNextStep();
    this.$prevStep = this._findPrevStep();

    if (this.onSwitchCallback) {
      this.onSwitchCallback(from, to, direction, skip);
    } else {
      from.hide();
      to.show();
    }
  }

  onForward() {
    if (!this.$nextStep.length || !this.canProceed(this.$currStep)) return;

    this.onSwitch(this.$currStep, this.$nextStep, 'forward', () => {
      this.$currStep = this.$prevStep;
      this.onForward();
    });
  }

  onBackward() {
    if (!this.$prevStep.length) return;

    this.onSwitch(this.$currStep, this.$prevStep, 'backward', () => {
      this.$currStep = this.$nextStep;
      this.onBackward();
    });
  }

  _findNextStep() {
    if (this.$steps.length == 1) return $(null);

    let currStepIdx = this.$steps.index(this.$currStep);

    if (this.loop && this.$steps.length == currStepIdx + 1) {
      return this.$steps.first();
    } else {
      return $(this.$steps[currStepIdx + 1]);
    }
  }

  _findPrevStep() {
    if (this.$steps.length == 1) return $(null);

    let currStepIdx = this.$steps.index(this.$currStep);

    if (this.loop && currStepIdx == 0) {
      return this.$steps.last();
    } else {
      return $(this.$steps[currStepIdx - 1]);
    }
  }
}

jQuery.fn.extend({
  stepper: function (options) {
    return this.each(function() {
      if ($.data(this, "pluginStepper")) {
        let instance = $.data(this, "pluginStepper");
        instance.destroy();
      }

      $.data(this, "pluginStepper", new Stepper(this, options || {}));
    });
  }
})

antiflicker((resolve, reject) => {
  $(".js-stepper").stepper();
  resolve(true);
});
