LemonFrog.BlockedUsers = (function () {
  const moduleName = "BlockedUsers";

  function init() {
    $(".js-confirmation-dialog").on("click", openConfirmationDialog)
  }

  function openConfirmationDialog() {
    const userNameFields = $(".js-confirmation-name");
    const confirmationBtn = $(".js-confirmation-link");

    confirmationBtn.attr("href", this.getAttribute("href"));
    userNameFields.html(this.getAttribute("data-user-name"));
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.BlockedUsers);
