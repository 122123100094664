LemonFrog.AdminMultiSelect = (function() {
  const moduleName = 'AdminMultiSelect';
  const wrapperSelector = `[data-module='${moduleName}']`
  const actualInputSelector = '.js-actual-input'
  const fakeCheckboxSelector = '.js-fake-checkbox'

  function init() {
    $(document).on('input', fakeCheckboxSelector, checkboxesValuesToString)
  }

  function checkboxesValuesToString() {
    let parent = $(this).closest(wrapperSelector)
    let actualInput = parent.find(actualInputSelector)
    let checkboxes = parent.find(fakeCheckboxSelector)
    let values = []

    checkboxes.each((_, checkbox) => {
      if ($(checkbox).is(':checked')) {
        values.push($(checkbox).val())
      }
    })

    actualInput.val(values.join(' '))
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.AdminMultiSelect);
