LemonFrog.RateComponent = (function() {
  const moduleName = 'RateComponent';

  function init() {
    $(document).on('click', '.rate-star-container.clickable', onStarClick)
  }

  function onStarClick() {
    const $stars = $(this).parent().children('.rate-star-container');
    const $input = $stars.parents('.rate-component').find('input')
    $stars.removeClass('selected');

    const idx = $stars.index($(this))
    $input.val(idx + 1);
    $stars.slice(0, idx + 1).addClass('selected');
  }

  return {
    init: init,
    moduleName: moduleName
  };
}());

LemonFrog.initModule(LemonFrog.RateComponent);
