import { updateQueryStringParameter } from '/helpers/url';

LemonFrog.GoogleSignUp = (function () {
  function init() {
    if ($(".js-google-sign-up-block").length == 0) return;

    if ($('.js-user-role:checked').length) {
      setRoleForGoogleUrl($('.js-user-role:checked').val());
    }

    initEvents();
  }

  function initEvents() {
    $(".js-user-role").on("change", function () {
      setRoleForGoogleUrl($(this).val());
      setRoleDataAttribute($(this).data("role"));
    });

    $(document).ready(function () {
      let role = $(".js-user-role:checked").data("role");
      setRoleDataAttribute(role);
    });
  }

  function updateGoogleFormUrl(attr, value, form) {
    form = form || $(".js-oauth-form");
    form.each(function() {
      const url = $(this).attr("action");
      const newUrl = updateQueryStringParameter(url, attr, value);
      $(this).attr("action", newUrl);
    });
  }

  function setRoleForGoogleUrl(role_id) {
    updateGoogleFormUrl("role_id", role_id);
  }

  function setRoleDataAttribute(role) {
    $(".js-oauth-form").attr("data-role", role);
  }

  return {
    init: init
  }
}());
