LemonFrog.ReportAndBlockMenu = (function () {
  const moduleName = 'ReportAndBlockMenu';
  const block_btn = '.report-and-block-menu__report-block-btn';
  const block_content = '.report-and-block-menu__content';

  function init() {
    hideOnScroll();
    hideOnBlur();
    showOnClick();
  }

  function hideOnScroll() {
    $(window).scroll(function () {
      $(block_btn).blur()
      $(block_content).css("display", "none");
    });
  }

  function hideOnBlur() {
    $(block_btn).on("click", function() {
      $(block_content).css("display", "block");
    });
  }

  function showOnClick() {
    $(document).click(function (event) {
      if (!$(event.target).closest(block_btn).length) {
        $(block_content).css("display", "none");
      }
    });
  }

  return {
    init: init,
    moduleName: moduleName
  };
}());

LemonFrog.initModule(LemonFrog.ReportAndBlockMenu);
