LemonFrog.Review = (function() {
  const moduleName = 'Review';

  function init(selector = '.js-readmore-review-comment') {
    $(selector).readmore({
      moreLink: '<div class="new-styling mt-4"><a class="new-styling text-small" href="#">' + __['readmore']['more'] + '</a></div>',
      lessLink: '<div class="new-styling mt-4"><a class="new-styling text-small" href="#">' + __['readmore']['less'] + '</a></div>',
      collapsedHeight: 72,
      heightMargin: 24
    });
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.Review);
