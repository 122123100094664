LemonFrog.ProgressBarComponent = (function () {
  const moduleName   = "ProgressBarComponent";

  function init() {
    $(document).on("turbo:submit-end", closeDialogAfterAllFrameReloaded)
  }

  function closeDialogAfterAllFrameReloaded($event) {
    let result = $event.originalEvent.detail;
    let form = result.formSubmission.formElement;

    Promise.all($("turbo-frame").map((frame) => frame.loaded)).then(() => {
      if (result.success && form.dataset["turboDialogDismissOnSuccess"]) {
        LemonFrog.Dialog.dismissDialog($(form).parents('.dialog'));
      }
    });
  }

  return {
    init: init,
    moduleName: moduleName
  };
}());

LemonFrog.initModule(LemonFrog.ProgressBarComponent);
