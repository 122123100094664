LemonFrog.InsuranceFormComponent = (function () {
  function init() {
    $(document).on("change", ".js-consent-checkbox input", toggleFormSubmit)
  }

  function toggleFormSubmit(e) {
    const consentCheckbox = $(e.target),
      submitButton = consentCheckbox.closest("form").find("[type=submit]");
    submitButton.prop('disabled', !consentCheckbox.is(':checked'));
  }

  return {
    init: init
  }
}());

LemonFrog.InsuranceFormComponent.init();