import { getCookie } from '/helpers/cookies';
import { getUrlParameter } from '/helpers/url';

$(document).ready(function () {
  $('#user_login').val(getCookie('user_email'));
});

// https://makandracards.com/makandra/3877-re-enable-submit-buttons-disabled-by-the-disabled_with-option
$(document).ready(function () {
  $(window).bind('pageshow', function (event) {
    $.rails.enableFormElements($($.rails.formSubmitSelector));
  });
});

$(document).ready(function () {
  $(document).on('click', 'a[data-trigger-submenu]', function (e) {
    e.preventDefault();
    $('#' + $(this).data('trigger-submenu')).toggle();
  });
});

$(document).ready(function () {
  let showDialogParameter = Boolean(getUrlParameter("dialog_onload"));
  let dialogSelector = $(".dialog[data-show-on-load=true]");

  if (showDialogParameter) {
    LemonFrog.Dialog.showDialog(dialogSelector)
  }
})

$(document).ready(function () {
  $('.js-mobile-menu-open, .js-mobile-menu-close').on('click', function () {
    $('.js-mobile-menu-nav').toggleClass('mobile-menu-visible');
    $('.js-mobile-menu-background').toggleClass('visible-sm');
    $(document.body).toggleClass('new-styling overflow-hidden');
  })
})

$(document).ready(function () {
  let parent = $('.js-more-less-component')

  parent.each(function(index, elem) {
    let showMore = $(elem).find('[data-show-more]')
    let showLess = $(elem).find('[data-show-less]')
    let hiddenContent = $(elem).find('[data-show-more-content]')
    let icon = $(elem).find('.js-more-less-icon')

    if (hiddenContent.attr('expanded') == 'true') {
      hiddenContent.show();
      showMore.hide();
      showLess.show();
      icon.removeClass('rotate-270').addClass('rotate-90')
    } else {
      hiddenContent.hide();
      showLess.hide();
      showMore.show();
    }
  })

  $(document).on('click', '[data-show-more], [data-show-less]', function (event) {
    let parent = $(event.target).closest('.js-more-less-component')
    let content = parent.find('[data-show-more-content]').first();
    let showMore = parent.find('[data-show-more]');
    let showLess = parent.find('[data-show-less]');
    let icon = parent.find('.js-more-less-icon');

    $(content).slideToggle(200);
    $(showMore).toggle();
    $(showLess).toggle();
    $(icon).toggleClass('rotate-270 rotate-90');
  });
})
