import { throttle } from '/helpers/debouncing';

LemonFrog.Carousel = (function() {
  const moduleName = 'Carousel';

  function init($el) {
    let currItemIdx = 0,
        $carousel = $el.data("carousel") ? $el.find($el.data("carousel")) : $el,
        $thumbs = $el.find($el.data("carouselThumbs")).children(),
        $nextBtn = $el.find($el.data("carouselNext")),
        $prevBtn = $el.find($el.data("carouselPrev")),
        itemWidth, itemsCount, autoplayTimerId = null;

    setItemsWidthAndCount();

    function setItemsWidthAndCount() {
      // default values
      itemWidth = $carousel.children().first().outerWidth();
      itemsCount = $carousel.children().length;
      if ($thumbs.length) {
        $thumbs.show(); // restore all thumbs on resize
      }
      let displayedItemsCount = Math.floor($carousel.width()/itemWidth);
      if (displayedItemsCount > 1) { // more than one item in a row
        itemWidth = $carousel.children().eq(1).offset().left - $carousel.offset().left; // width w/ spacing between items

        const skippedItems = displayedItemsCount - 1; // extra items already displayed
        itemsCount = $carousel.children().length - skippedItems;

        if ($thumbs.length) {
          $thumbs.slice(-skippedItems).hide(); // don't display thumbs for extra items
        }
      }
    }

    function onScroll() {
      const itemIdx = Math.round($(this).scrollLeft() / itemWidth);
      if (itemIdx === currItemIdx) return;

      $thumbs.eq(currItemIdx).removeClass("active");
      $thumbs.eq(itemIdx).addClass("active");
      currItemIdx = itemIdx;
    }

    function onThumbClick() {
      pauseAutoplay();
      const itemIdx = $thumbs.index(this);
      if (itemIdx === currItemIdx) return;

      scrollTo(itemIdx);
    }

    function scrollTo(idx) {
      $carousel.get(0).scrollTo({ left: idx * itemWidth, behavior: "smooth" });
    }

    function scrollNext() {
      let idx = currItemIdx + 1;
      scrollTo(idx > itemsCount - 1 ? 0 : idx);
    }

    $thumbs.eq(currItemIdx).addClass("active");
    $carousel.scroll(throttle(200, onScroll));
    $carousel.on('touchstart touchmove', pauseAutoplay);

    $thumbs.click(onThumbClick);
    $nextBtn.click(() => {
      pauseAutoplay();
      scrollNext();
    });
    $prevBtn.click(() => {
      pauseAutoplay();
      let idx = currItemIdx - 1;
      scrollTo(idx < 0 ? itemsCount - 1 : idx);
    });
    $(window).resize(() => {
      setItemsWidthAndCount();
    });

    if ($el.data("carouselAutoplay")) {
      startAutoplay();
    }

    function startAutoplay() {
      autoplayTimerId = setInterval(scrollNext, 3000);
    }

    function pauseAutoplay() {
      if(autoplayTimerId) {
        clearInterval(autoplayTimerId);
        autoplayTimerId = null;
        setTimeout(startAutoplay, 10000);
      }
    }
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.Carousel, { perElement: true });
