LemonFrog.ArgumentsDialog = (function () {
  const moduleName = 'ArgumentsDialog';

  const checkboxesSubmit = '.js-checkboxes-submit';
  const otherInputSubmit = '.js-submit-other-dialog';
  const openInput = '.js-reports-open-input';

  function init () {
    disableSubmitButton(checkboxesSubmit);
    disableSubmitButton(otherInputSubmit);
    clearOnDismiss();
    toggleCheckboxesClass();
    toggleCheckboxesSubmit();
    toggleOtherInputSubmit();
  }

  function clearOnDismiss () {
    $(`[data-module='${moduleName}']`).find('.js-dismiss-dialog').click(function () {
      $('.js-dismiss-clear-checkbox').prop('checked', false);
      $('.report-dialog__arguments-checkbox').removeClass('report-dialog__arguments-checkbox--selected');
      disableSubmitButton(checkboxesSubmit);

      $(openInput).val('');
      disableSubmitButton(otherInputSubmit);
    });
  }

  function toggleCheckboxesClass () {
    $('.report-dialog__arguments-checkbox').click(function () {
      $(this).toggleClass('report-dialog__arguments-checkbox--selected');
    });
  }

  function toggleCheckboxesSubmit () {
    $('.report-reason-checkbox').click(function () {
      if ($('.js-report-form input:checkbox:checked').length > 0) {
        enableSubmitButton(checkboxesSubmit);
      } else {
        disableSubmitButton(checkboxesSubmit);
      }
    });
  }

  function toggleOtherInputSubmit () {
    $(openInput).on('input', function () {
      if ($(openInput).val().length > 0) {
        enableSubmitButton(otherInputSubmit);
      } else {
        disableSubmitButton(otherInputSubmit);
      }
    });
  }

  function disableSubmitButton (button) {
    $(button).each(function () {
      $(this).prop('disabled', true);
    });
  }

  function enableSubmitButton (button) {
    $(button).each(function () {
      $(this).prop('disabled', false);
    });
  }

  return {
    init: init,
    moduleName: moduleName
  };
}());

LemonFrog.initModule(LemonFrog.ArgumentsDialog);
