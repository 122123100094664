LemonFrog.MembershipSelect = (function () {
  const
    moduleName = 'MembershipSelect',
    companyNameSel = "#company-name",
    companyWebsiteSel = "#company-website",
    membershipSectionSel = "#membership-type",
    companyFieldMeaning = "company_membership",
    companyMeaningSel = "[data-meaning=" + companyFieldMeaning + "]"
  ;

  function init() {
    initEvents();
  }

  function initEvents() {
    $(membershipSectionSel).find("input[type=checkbox]").change(function(el) {
      let isCompany = $(membershipSectionSel).find("input[type=checkbox]").is(":checked");
      toggleCompanyNameAndWebsite(isCompany);
    });

    if ($(membershipSectionSel).find("#membership-checked").length) {
      toggleCompanyNameAndWebsite(true);
    }
  }

  function toggleCompanyNameAndWebsite(companySelected) {
    toggleField(companyNameSel, companySelected);
    toggleField(companyWebsiteSel, companySelected);
  }

  function toggleField(selector, show) {
    if (show) {
      $(selector).removeClass("hidden");
    } else {
      $(selector).addClass("hidden");
      $(selector).find("input[type=text]").val("");
    }
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.MembershipSelect);
