LemonFrog.ScrollRow = (function() {
  function init() {
    addCallbacks();
  }

  function addCallbacks() {
    $(document).on('click', '[data-scroll-row-forward]', onForward);
    $(document).on('click', '[data-scroll-row-backward]', onBackward);
  }

  function removeCallbacks() {
    $(document).off('click', '[data-scroll-row-forward]', onForward);
    $(document).off('click', '[data-scroll-row-backward]', onBackward);
  }

  function onForward(e) {
    let $row = $($(e.currentTarget).data('scroll-row-forward'));
    $row.animate(
      { scrollLeft: $row.scrollLeft() + $row.innerWidth() },
      { duration: 500, easing: 'easeOutCubic', start: removeCallbacks, always: addCallbacks }
    );
  }

  function onBackward(e) {
    let $row = $($(e.currentTarget).data('scroll-row-backward'));
    $row.animate(
      { scrollLeft: $row.scrollLeft() - $row.innerWidth() },
      { duration: 500, easing: 'easeOutCubic', start: removeCallbacks, always: addCallbacks }
    );
  }

  return {
    init: init,
  }
}());

LemonFrog.ScrollRow.init();
