LemonFrog.HomeExperiences = (function () {
  let moduleName = "HomeExperiences",
      moduleSel  = `[data-module="${moduleName}"]`;

  function init() {
    $("#home-experiences-texts").stepper({ onSwitch: onTextSwitch, loop: true });
    $("#home-experiences-images").stepper({ onSwitch: onImageSwitch, loop: true });

    $(moduleSel).swipe({
      swipe: function (event, direction) {
        if (direction === 'left') {
          $("#home-experiences-texts").trigger("stepper-forward");
        } else if (direction === 'right') {
          $("#home-experiences-texts").trigger("stepper-backward");
        }
      }
    });
  }

  function onTextSwitch(from, to, direction, skip) {
    from.stop(true, true).hide();
    to.show('shift', { easing: 'easeOutCubic' }, 300);
    $("#home-experiences-images").trigger(`stepper-${direction}`);
  }

  function onImageSwitch(from, to, direction, skip) {
    from.stop(true, true).hide();
    to.show('shift', { easing: 'easeOutCubic', from: "right" }, 300);
  }

  return {
    moduleName: moduleName,
    init: init,
  }
}());

LemonFrog.initModule(LemonFrog.HomeExperiences, { antiflicker: true });
