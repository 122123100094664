import { mapHTMLAttributesToObject } from '/helpers/html';

LemonFrog.Datepicker = (function () {
  const moduleName = 'Datepicker';
  const datePickerClass = '.js-datepicker';

  function init () {
    bindDefaultOptions();
    initDatepicker(datePickerClass);
  }

  function initDatepicker(target) {
    $(target).each(function () {
      let $input = $(this);
      let options = mapHTMLAttributesToObject(
        this,
        'data-datepicker-',
        true
      );
      options.onSelect = function () {
        $input.trigger('input');
      };
      options.beforeShow = function (input, inst) {
        let parentFixedBlock = $(input).parents('.fixed-bottom-block');
        if (!parentFixedBlock.length) return;

        setTimeout(function() {
          inst.dpDiv.css('z-index', parseInt(parentFixedBlock.css('z-index'), 10) + 1);
        }, 0)
      }

      options.onUpdateDatepicker = function (inst) {
        setTimeout(function() {
          inst.dpDiv.addClass('new-styling');
          inst.dpDiv.find('.ui-datepicker-title select')
            .wrap('<div class="new-styling relative datepicker-select-wrap"></div>');
        }, 0);
      }

      options.onChangeMonthYear = function(year, month, inst) {
        if($input.val()) {
          $(this).datepicker('setDate', new Date(year, month - 1, inst.selectedDay));
          $input.trigger('input');
        }
      }

      options.firstDay = 1;

      $input.closest('.js-datepicker-wrapper').find('.js-datepicker-icon').each(function () {
        $(this).click(function (event) {
          if (!$input.datepicker('widget').is(':visible') &&
              !$input.prop("disabled")) {
            $input.datepicker('show');
          }
        });
      });

      $(this).datepicker(options);
    });

  }

  function bindDefaultOptions() {
    $.datepicker.setDefaults(
      $.extend(
        {
          changeMonth: true,
          changeYear: true
        },
        $.datepicker.regional[gon.locale]
      )
    );
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.Datepicker);
