LemonFrog.CheckboxComponent = (function () {
  const moduleName = "CheckboxComponent";
  const moduleRef = `[data-module=${moduleName}]`;

  function init() {
    $(document).on('click', moduleRef, function (e) {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();

      const checkbox = $(this).find('input'),
            checked = !checkbox.prop('checked');

      checkbox.prop('checked', checked);
      checkbox.trigger('change').trigger('input');
    });
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.CheckboxComponent);
