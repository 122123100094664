import { getBreakpoint } from '/helpers/ui';

LemonFrog.TooltippedFields = (function() {
  const moduleName = 'TooltippedFields';
  const tooltipToggle = '.js-mobile-tooltipped';

  function init() {
    $(document).on('click', tooltipToggle, toggleMobileTooltip)
  }

  function toggleMobileTooltip() {
    if (getBreakpoint() == 'xs') $(this).find(".js-mobile-tooltip").slideToggle(200);
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.TooltippedFields);
