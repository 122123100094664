import { setCookie } from '/helpers/cookies';

LemonFrog.ReviewRequestsComponent = (function() {
  const moduleName = 'ReviewRequestsComponent';

  function init() {
    $(document).on('click', '.js-dismiss-review-request', function (e) {
      e.preventDefault();
      $(this).parents(`[data-module=${moduleName}]`).remove();
      setCookie('dismissed-review-request-banner', true, new Date(Date.now() + 12096e5)); // +14 days
    });
  }

  return {
    moduleName: moduleName,
    init: init,
  }
}());

LemonFrog.initModule(LemonFrog.ReviewRequestsComponent);
