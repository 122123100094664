import { getCookie, removeCookie, setCookie } from '/helpers/cookies';

LemonFrog.RegisterForm = (function () {
  const moduleName = "RegisterForm",
      moduleSel  = `[data-module="${moduleName}"]`;

  function init() {
    if ($(".js-stepper-step", moduleSel).length == 1) {
      onSingleStep();
    }

    initEvents();
    LemonFrog.GoogleSignUp.init();
  }

  function initEvents() {
    $('#user_email', moduleSel).on('blur', function () {
      $(this).mailcheck({
        domains: window.possibleEmailDomains, // optional
        suggested: function (element, suggestion) {
          $('.email-suggestion-mail', moduleSel).html("<a href='#' class='update-email' data-domain='" + suggestion.domain + "'>" + '<span>' + suggestion.domain + '</span></a>?');
          $('.email-suggestion-text', moduleSel).slideDown();
        },
        empty: function (element) {
          $('.email-suggestion-text', moduleSel).hide();
          $('.email-suggestion-mail', moduleSel).html('');
        }
      });
    });

    $(document).on('click', `${moduleSel} a.update-email`, function (event) {
      event.preventDefault();
      let correctedEmail = $('#user_email', moduleSel).val().replace(/@.*/, '@' + $(event.currentTarget).data('domain'));
      $('#user_email', moduleSel).val(correctedEmail);
      $('.email-suggestion-mail', moduleSel).html('');
      $('.email-suggestion-text', moduleSel).hide();
    });

    $('.js-user-role', moduleSel).on('change', function() {
      $(moduleSel).trigger('stepper-forward');
    });
    $('.js-user-role', moduleSel).on('change', showPromoTexts);
    $(moduleSel).stepper({ onSwitch: onSwitch });
  }

  function onSwitch(from, to, direction, skip) {
    const toFadeOut = from.is(emailPasswordStep()) ? from.add(emailPasswordStepExtra()) : from;
    const toFadeIn = to.is(emailPasswordStep()) ? to.add(emailPasswordStepExtra()) : to;

    if (to.is(transparentPricingStep()) && selectedRole() != "consumer") return skip();

    toFadeOut.stop(true, true).fadeOut(150);
    toFadeIn.delay(150).fadeIn(150).promise().always(() => $('html, body').animate({scrollTop: 0}));

    if(to.is(registerInitStep())) {
      hidePromoTexts();
      resetRegisterScreen();
    } else {
      showPromoTexts();
    }
  }

  function hidePromoTexts(animationTime = 300) {
    let $promo = $(`.js-register-promo-${selectedRole()}`);
    if ($promo.is(':hidden')) return;
    $promo.hide();
  }

  function showPromoTexts(animationTime = 300) {
    $('.js-register-promo-general').hide();
    let $promo = $(`.js-register-promo-${selectedRole()}`);
    if ($promo.is(':visible')) return;
    $promo.show('shift', { easing: 'easeOutCubic', from: 'left' }, animationTime);
  }

  function resetRegisterScreen(animationTime = 300) {
    $('.js-new-user-form').trigger('reset');
    $('.js-register-promo-general').show('shift', { easing: 'easeOutCubic', from: 'left' }, animationTime);
  }

  function onSingleStep() {
    showPromoTexts(0);
    emailPasswordStepExtra().show();
  }

  function characterImage() {
    return $(".js-register-layout-character");
  }

  function selectedRole() {
    return $('.js-user-role:checked', moduleSel).data('role');
  }

  function transparentPricingStep() {
    return $(".js-transparent-pricing-step", moduleSel);
  }

  function emailPasswordStep() {
    return $(".js-email-password-step", moduleSel);
  }

  function emailPasswordStepExtra() {
    return $(".js-email-password-step-extra", moduleSel);
  }

  function registerInitStep() {
    return $(".js-register-init-step", moduleSel);
  }

  return {
    moduleName: moduleName,
    init: init,
    onSingleStep: onSingleStep
  }
}());

LemonFrog.initModule(LemonFrog.RegisterForm, { antiflicker: true });

$(document).ready(function () {
  $('#failure-link').on('click', function () {
    $('#failure-link').hide();
    $('#failure-hints').slideDown();
    return false;
  });

  setCookie('test', 1);
  if (getCookie('test')) {
    removeCookie('test');
  } else {
    $('#cookies-alert').show();
  }
});
