LemonFrog.Reviews = (function() {
  const moduleName = 'Reviews';

  function init() {
    $('[data-module="Reviews"] button[data-show-more]').one('click', function () {
      setTimeout(function() {
        LemonFrog.Review.init(".profile-reviews .js-readmore-review-comment");
      }, 0);
    });
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())
LemonFrog.initModule(LemonFrog.Reviews);
