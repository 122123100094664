LemonFrog.Select = (function() {
  const moduleName = 'Select';
  const wrapperSelector = '.select-component';
  const itemSelector = '.select-component__item';
  const actualSelect = '.select-component__select';
  const placeholderSelector = '.select-component__placeholder'

  function init() {
    $(document).on("click", itemSelector, selectItem)
    $(document).on("click", '.js-clear-icon', clearSelect)
    $(document).on("change", actualSelect, updateClearIconVisibility)
  }

  function selectItem() {
    let $wrapper = $(this).closest(wrapperSelector)
    let $select = $wrapper.find(actualSelect)
    let $placeholder = $wrapper.find(placeholderSelector)

    $(itemSelector).removeClass("checked")
    $(this).addClass("checked")

    $select.val($(this).attr("selected_value"));
    $select.trigger('change').trigger('input');

    if ($(this).attr("selected_value") == undefined) {
      $placeholder.val(null).trigger('change');
    } else {
      $placeholder.val($(this).text().trim()).trigger('change');
    }
  }

  function clearSelect() {
    let $wrapper = $(this).closest(wrapperSelector)
    let $select = $wrapper.find(actualSelect)
    let $placeholder = $wrapper.find(placeholderSelector)

    $(itemSelector).removeClass("checked")

    $select.val(null)
    $select.trigger('change').trigger('input');
    $placeholder.val(null).trigger('change');
  }

  function updateClearIconVisibility() {
    let $wrapper = $(this).closest(wrapperSelector)

    $wrapper.find('.js-clear-icon').toggleClass('visible', Boolean($(this).val()))
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.Select);
