$(document).ready(function () {
  $(document).on('click', '.checkbox__label', function (event) {
    let input = $(this).parent().find('input[type=checkbox]');

    event.preventDefault();
    input.click();
  });

  $(document).on('click', '.radio__label', function (event) {
    let input = $(this).parent().find('input[type=radio]');

    event.preventDefault();
    input.trigger('click');
  });
});
