LemonFrog.AvailabilityTableComponent = (function () {
    const moduleSelector = "[data-module=AvailabilityTableComponent]",
        moduleWrapper = (el) => el.parents(moduleSelector);

    function init() {
        $(document).on("change", moduleSelector + " input[data-availability]", toggleAvailability);
        $(document).on("change", moduleSelector + " input[data-availability-title]", toggleAvailabilityTitle);
        $(document).on("mouseenter mouseout", moduleSelector + " input[data-availability-title] + label.clickable", hoverTitleRange);
        $(document).on("click", moduleSelector + "[data-preview='true']", (e) => { e.preventDefault(); })
    }

    function updateAvailability(wrapper) {
        const valueInput = wrapper.find("[data-availability-value]");

        let availabilityTable = new Array(28).fill(0);
        wrapper.find('input[data-availability]:checked').each((_, check) => {
            availabilityTable[$(check).val()] = 1
        });
        valueInput.val(availabilityTable.join(''));

        $(valueInput).parsley().validate();
    }

    function updateTitleLabel(wrapper, type, index) {
        const checked = wrapper.find("[data-"+ type + "=" + index + "]:checked"),
            total =  wrapper.find("[data-"+ type + "=" + index + "]");
        wrapper.find("input[data-availability-title=" + type + "][value=" + index + "]").prop("checked", checked.length === total.length)
    }

    function toggleAvailability() {
        const wrapper = moduleWrapper($(this));
        updateAvailability(wrapper);
        updateTitleLabel(wrapper, "range", $(this).data('range'));
        updateTitleLabel(wrapper, "day", $(this).data('day'));
    }

    function toggleAvailabilityTitle() {
        const wrapper = moduleWrapper($(this)),
            checked = $(this).is(":checked"),
            type = $(this).data('availability-title'),
            updateLabels = type === "day"? "range": "day",
            index = $(this).val();
        wrapper.find("[data-" + type + "=" + index + "]").each((_, check) => {
            $(check).prop("checked", checked);
        });
        updateAvailability(wrapper);
        wrapper.find("[data-availability-title=" + updateLabels + "]").each(updateIndex => {
            updateTitleLabel(wrapper, updateLabels, updateIndex)
        });
    }

    function hoverTitleRange() {
        const wrapper = moduleWrapper($(this)),
            type = $(this).prev('input').data('availability-title'),
            index = $(this).prev('input').val();
        $(this).toggleClass('bg-gray-0 border-color-gray-3');
        wrapper.find("[data-" + type + "=" + index + "]").each((_, check) => {
            $(check).next('label').find('span').toggleClass('bg-gray-0 border-color-gray-3');
        });
    }

    return {
        init: init
    }
}());

LemonFrog.AvailabilityTableComponent.init();
