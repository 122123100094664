import { getUrlParameter } from '/helpers/url';

LemonFrog.ContactCard = (function() {
  const moduleName = "ContactCard";
  const moduleRef = `[data-module="${moduleName}"]`;
  const consumerNameRef = '.js-request-review-name';
  const consumerIdRef = '.js-request-review-id';

  function init() {
    $(document).on("click", moduleRef, openRequestDialog);

    openDialogWhenPreselected();
  }

  function openRequestDialog() {
    const $this = $(this);

    $(consumerNameRef).html($this.data("contact-name"));
    $(consumerIdRef).val($this.data("contact-id"));
  }

  function openDialogWhenPreselected() {
    const consumerId = getUrlParameter("rev_id");
    if (!consumerId) return;

    const $userCard = $(`[data-contact-id="${consumerId}"]`);
    const dialogSelector = $userCard.data("open-dialog");

    openRequestDialog.call($userCard);
    LemonFrog.Dialog.showDialog(dialogSelector);
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.ContactCard);
