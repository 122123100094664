LemonFrog.SingleRangeComponent = (function() {
  const moduleName = 'SingleRangeComponent';
  const wrapper = '.single-range';
  const sliderSelector = '.single-range__input';

  function init() {
    $(wrapper).each(function() {
      let slider = $(this).find(sliderSelector);

      fillSlider($(this));
      updateDisplayedValue($(this));

      $(slider).on('input change', controlSlider)
    })
  }

  function controlSlider() {
    let parentWrapper = $(this).closest(wrapper);

    fillSlider(parentWrapper);
    updateHolderValues(parentWrapper);
    updateDisplayedValue(parentWrapper);
  }

  function fillSlider(wrapper) {
    let values = getValues(wrapper);
    let slider = wrapper.find(sliderSelector);
    let persantage = ((values.value-values.min)/(values.max-values.min))*100;

    slider.css('background', `linear-gradient(
      to right,
      #707070 ${persantage}%,
      #CACDCF ${persantage}%,
      #CACDCF 100%
    )`)
  }

  function updateHolderValues(wrapper) {
    let values = getValues(wrapper);

    wrapper.find('.single-range-value-holder').val(values.value);
  }

  function updateDisplayedValue(wrapper) {
    let values = getValues(wrapper);

    wrapper.find('.displayed-value').text(values.value);
  }

  function getValues(wrapper) {
    let slider = wrapper.find(sliderSelector);

    return {
      value: parseInt(slider.val()),
      min: parseInt(slider.attr('min')),
      max: parseInt(slider.attr('max')),
    }
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.SingleRangeComponent);
