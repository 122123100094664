class GuillotineHelper {
  constructor () {
    this.element = null;
  }

  init (object, initData, onChange = null) {
    this.element = object;
    this.initData = initData;
    this.onChange = onChange;
    return this.load(initData);
  }

  load (initData) {
    this.reloadInstance(initData);
    return this.element.on('load', () => {
      this.reloadInstance(initData);
      // Assuming that onChange is the function responsible for form value updates
      // See app/components/user_profile/attachments/editor_component/editor_component.js:28
      this.onChange.call(this.element, this.getData())
      // Could cause unexpected errors with any other function
    });
  }

  reloadInstance (initData) {
    if (this.getInstance()) {
      this.element.guillotine('remove');
    }
    return this.element.guillotine({
      width: 640,
      height: 640,
      init: initData,
      ...(this.onChange) && {onChange: this.onChange},
    });
  }

  getData () {
    return this.element.guillotine('getData');
  }

  getInstance () {
    return this.element.guillotine('instance');
  }

  getScale () {
    return this.getData().scale;
  }

  minScale () {
    return this.initData.minScale;
  }

  maxScale () {
    return 5;
  }

  rotateRight () {
    return this.element.guillotine('rotateRight');
  }

  zoomInFactor () {
    return this.getInstance().zoomInFactor;
  }

  scaleAfterZoomIn () {
    return this.getScale() * this.zoomInFactor();
  }

  imageSmallerThanCanvas () {
    return this.getData().x < 0 || this.getData().y < 0;
  }

  zoomIn () {
    if (this.scaleAfterZoomIn() >= this.maxScale()) {
      return;
    }
    return this.element.guillotine('zoomIn');
  }

  zoomOut () {
    this.element.guillotine('zoomOut');
    if (this.imageSmallerThanCanvas()) {
      return this.element.guillotine('zoomIn');
    }
  }

  zoomToFactor(factor) {
    const instance = this.getInstance();
    const resultingScale = this.getScale() * factor;
    if (!instance.enabled) return;

    if (resultingScale >= this.maxScale() || resultingScale <= this.minScale()) {
      return;
    }

    instance._zoom(factor);
    return resultingScale;
  }
}

export default GuillotineHelper;
