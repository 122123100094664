LemonFrog.UploadPhotoStepComponent = (function () {
  const moduleName = 'UploadPhotoStepComponent';
  const moduleRef = `[data-module="${moduleName}"]`;
  const uploadAreaRef = '.new-styling.upload-photo-step-component__area';
  const inputRef = 'input[type="file"]';

  function init() {
    $(moduleRef).find(uploadAreaRef).on('click', function(e) {
      if(!$(e.target).is('button')) {
        $(moduleRef).find(inputRef).click();
      }
    });
    $(moduleRef).find(inputRef).on('click', function (e) { e.stopPropagation(); })
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.UploadPhotoStepComponent);
