import { getBreakpoint } from '/helpers/ui';

LemonFrog.StickyActions = (function() {
  const moduleName = 'StickyActions';

  function init() {
    if (getBreakpoint() != 'xs') return;

    $(`[data-module="${moduleName}"]`).each((i, el) => initStickyObserver(el));
  }

  function initStickyObserver(el) {
    // https://stackoverflow.com/a/57991537
    new IntersectionObserver(
      ([e]) => e.target.classList.toggle('sticky-actions--stuck', e.intersectionRatio < 1),
      {threshold: [1]}
    ).observe(el);
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.StickyActions);
