function assignRoleAttribute (element) {
  let $form = $(element).closest('form');
  let role = $form.find('.js-user-role:checked').data('role');
  $form.attr('data-role', role);
}

$(document).ready(function () {
  let $forms = $('.js-new-user-form');

  $forms.find('.js-user-role').each(function () {
    assignRoleAttribute(this);
  });

  $forms.find('.js-user-role').on('change', function (event) {
    assignRoleAttribute(event.target);
  });
});
