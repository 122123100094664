LemonFrog.Alert = (function() {
  const moduleName = 'Alert';
  const moduleSel = '[data-module="' + moduleName + '"]';
  const closeBtnSel = moduleSel + ' [data-dismiss="alert"]';

  function init() {
    $(document).on('click', closeBtnSel, function () {
      $(this).closest(moduleSel).remove();
    })
  }

  return {
    init: init
  }
}());

LemonFrog.Alert.init();
