$(document).ready(function () {
  $('.js-show-password').each(function () {
    let $toggle = $(this);
    let $hideIcon = $toggle.find('.js-show-password-icon-hide');
    let $showIcon = $toggle.find('.js-show-password-icon-show');
    let $parent = $(this).closest('.input-field__box, .password-field');
    let $input = $parent.find('input[type=\'password\']');

    $toggle.click(function () {
      if ($input.attr('type') === 'password') {
        $hideIcon.addClass('hidden');
        $showIcon.removeClass('hidden');
        $input.attr('type', 'text');
      } else {
        $showIcon.addClass('hidden');
        $hideIcon.removeClass('hidden');
        $input.attr('type', 'password');
      }
    });
  });
});
