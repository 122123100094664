LemonFrog.SwipeIconComponent = (function () {
  const moduleName = 'SwipeIconComponent';
  const DISAPPEAR_DISTANCE = 30;

  function init($swipeIcon) {
    const $scrollable = $swipeIcon.siblings('.js-scrollable-container');

    if (!$scrollable.length) return;

    new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting && $scrollable[0].scrollWidth > $scrollable[0].parentNode.offsetWidth) {
        $swipeIcon.show();
      } else {
        $swipeIcon.hide();
      }
    }, {
      threshold: 0
    }).observe($scrollable[0]);

    if($swipeIcon.data('hide-on-scroll')) {
      $scrollable.scroll(function () {
        const scrolledDistance = $(this).scrollLeft();

        if (scrolledDistance > DISAPPEAR_DISTANCE) {
          $swipeIcon.fadeOut();
        } else {
          $swipeIcon.fadeIn();
        }
      });
    }
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.SwipeIconComponent, { perElement: true });
