LemonFrog.DashboardErrorNotification = (function() {
  const moduleName = 'DashboardErrorNotification';

  function init() {
    $(`[data-module="${moduleName}"] .js-readmore-notification`).each((_,notification) => {
      const $notification = $(notification),
            mobile = $notification.parents('.visible-xs').length
      $notification.readmore({
        moreLink: '<div class="new-styling mt-4"><a class="new-styling color-black fw-bold" href="#">' + __['readmore']['more'] + '</a></div>',
        lessLink: '<div class="new-styling mt-4"><a class="new-styling color-black fw-bold" href="#">' + __['readmore']['less'] + '</a></div>',
        collapsedHeight: mobile? 62 : 72,
        heightMargin: 24
      });
    })
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.DashboardErrorNotification);
