(function ($) {
  let IS_IOS = /iphone|ipad/i.test(navigator.userAgent);
  $.fn.preventDoubleTap = function () {
    if (IS_IOS) {
      $(this).bind('touchstart', function preventZoom (e) {
        let t2 = e.timeStamp;
        let t1 = $(this).data('lastTouch') || t2;
        let dt = t2 - t1;
        let fingers = e.originalEvent.touches.length;

        $(this).data('lastTouch', t2);
        if (!dt || dt > 500 || fingers > 1) {
          return;
        }
        e.preventDefault();
        $(this).trigger('click').trigger('click');
      });
    }
  };
})(jQuery);

$(document).ready(function () {
  $('.no_double_tap').preventDoubleTap();
});
