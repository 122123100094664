import Sortable from 'sortablejs/modular/sortable.core.esm.js';

LemonFrog.AttachmentsListComponent = (function () {
  const moduleName = 'AttachmentsListComponent';
  const listReference = `[data-module="${moduleName}"]`;
  const sotableOptions = {
    sort: true,
    draggable: '.attachments-list__item',
    handle: '.attachments-list__item .item-button.drag-and-drop',
    animation: 200,
    easing: 'cubic-bezier(1, 0, 0, 1)',
    ghostClass: 'opacity-0',
    chosenClass: 'chosenClass', // to override standart behavior
    dragClass: 'elevation-30',
    swapThreshold: 0.8,
    onSort: onSort,
  }

  function init() {
    Sortable.create(document.querySelector(listReference), sotableOptions)
  }

  function onSort(evt) {
    $(evt.item).find('input[name="attachment[position]"]').val(evt.newIndex);
    $(evt.item).find('.js-submit-sort').click()
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.AttachmentsListComponent);
