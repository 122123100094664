import { getBreakpoint } from '/helpers/ui';

LemonFrog.Users = (function () {
  const moduleName = 'Users';
  const descriptionGradient = '.js-profile-description-wrapper';

  function init () {
    const registerLink = $('.js-readmore-description-sign-up').find('a');

    initReadmore(collapsedHeight(registerLink), registerLink);
  }

  function collapsedHeight(registerLink) {
    if (registerLink.length){
      return getBreakpoint() === 'xs' ? 125 : 175;
    } else {
      return getBreakpoint() === 'xs' ? 250 : 350;
    }
  }

  function initReadmore (collapsedHeight, registerLink) {
    const heightMargin = 100;

    // Removing only child gradient of element, if element is not enough higher
    $('.js-readmore-description').each((_, elem) => {
      // Getting gradient child for each elem, through his parent
      const targetGradient = $(elem).parents('.profile-block').find(descriptionGradient);

      // We should keep in mind we use "heightMargin" - when total size is not bigger than
      // height + allowed margin - "Read more" link is not shown at all
      if ($(elem).outerHeight() > (collapsedHeight + heightMargin)) {
        $(targetGradient).removeClass('hidden')
      }
    })

    $('.js-readmore-description').readmore({
      moreLink: '<div role="button" class="new-styling text-extra sm-text-default color-primary ta-center z-1 clickable">' + __['readmore']['more'] + '</div>',
      lessLink: '<div role="button" class="new-styling text-extra sm-text-default color-primary ta-center z-1 clickable">' + __['readmore']['less'] + '</div>',
      collapsedHeight: collapsedHeight,
      heightMargin: heightMargin,
      blockProcessed: function() {
        if (registerLink.length) {
          $('.js-readmore-description').siblings('[data-readmore-toggle]').replaceWith(registerLink);
        }
      },
      afterToggle: function(trigger, element, expanded) {
        // Getting gradient child for each elem, through his parent
        const targetGradient = $(element).parents('.profile-block').find(descriptionGradient);

        expanded ? targetGradient.hide() : targetGradient.show();
      }
    });
  }

  return {
    moduleName: moduleName,
    initReadmore: initReadmore,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.Users);
