LemonFrog.Collapse = (function() {
  const TRANSITION_DURATION = 350;

  function init() {
    $('.collapse').not('.open').hide();

    $(document).on('click', '[data-open-collapse]', openCollapseEventListener);
    $(document).on('click', '[data-close-collapse]', closeCollapseEventListener);
    $(document).on('click', '[data-toggle-collapse]', toggleCollapseEventListener);

    $(document).on('click', '[data-open-collapse][data-parent], [data-toggle-collapse][data-parent]', closeSiblingCollapsesEventListener);

    $(document).on('click', 'a[data-open-collapse], a[data-close-collapse], a[data-toggle-collapse]', function(e) {
      e.preventDefault();
    });
  }

  function openCollapseEventListener() {
    const collapse = $(this.dataset.openCollapse)
    const parent = this.dataset.parent && collapse.parents(this.dataset.parent)

    if (collapse.length === 0 ||
        collapse.is('.collapse.open, .collapse.transitioning') ||
        (parent && parent.find('.collapse.transitioning').length))
      return

    openCollapse(this.dataset.openCollapse)
  }

  function closeCollapseEventListener() {
    const collapse = $(this.dataset.closeCollapse)

    if (collapse.length === 0 ||
        collapse.is('.collapse:not(.open), .collapse.transitioning'))
      return

    closeCollapse(this.dataset.closeCollapse)
  }

  function toggleCollapseEventListener() {
    const collapse = $(this.dataset.toggleCollapse)
    const parent = this.dataset.parent && collapse.parents(this.dataset.parent)

    if (collapse.length === 0 ||
        collapse.is('.collapse.transitioning') ||
        (parent && parent.find('.collapse.transitioning').length))
      return

    if (collapse.hasClass('open'))
      closeCollapse(this.dataset.toggleCollapse)
    else
      openCollapse(this.dataset.toggleCollapse)
  }

  function closeSiblingCollapsesEventListener() {
    const targetCollapse = this.dataset.openCollapse || this.dataset.toggleCollapse

    $(this.dataset.parent).find('.collapse.open').not(targetCollapse).each(function() {
      closeCollapse('#' + this.id)
    })
  }

  function triggersSelector(collapseID) {
    return `[data-open-collapse='${collapseID}'], [data-close-collapse='${collapseID}'], [data-toggle-collapse='${collapseID}']`;
  }

  function openCollapse(collapseSelector) {
    const $trigger = $(triggersSelector(collapseSelector));

    $trigger.addClass('transitioning').removeClass('collapsed');
    $(collapseSelector).addClass('transitioning')
      .slideDown(TRANSITION_DURATION, function() {
      $(this).removeClass('transitioning').addClass('open');
      $trigger.removeClass('transitioning');
    })
  }

  function closeCollapse(collapseSelector) {
    const $trigger = $(triggersSelector(collapseSelector));

    $trigger.addClass('transitioning').addClass('collapsed');
    $(collapseSelector).addClass('transitioning').removeClass('open')
      .slideUp(TRANSITION_DURATION, function() {
      $(this).removeClass('transitioning');
      $trigger.removeClass('transitioning');
    })
  }

  return {
    init: init,
    openCollapse: openCollapse,
    closeCollapse: closeCollapse
  }
}());

$(function() {
  LemonFrog.Collapse.init();
})
