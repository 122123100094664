import { getBreakpoint } from '/helpers/ui';

LemonFrog.UserCard = (function() {
  const moduleName = 'UserCard',
        moduleSel  = `[data-module="${moduleName}"]`;

  function init($card) {
    if (['xs', 'sm'].includes(getBreakpoint())) {
      fitFeaturedFields($card)
      $card.on('click', '.scrollable-container', triggerUserLink.bind($card))
    }
  }

  function fitFeaturedFields($card) {
    const $fieldsContainer = $card.find('.js-featured-fields')
    const $lastField = $fieldsContainer.find('.profile__featured-field').last()

    new IntersectionObserver((entries, observer) => {
      if (!entries[0].isIntersecting) {
        $fieldsContainer.addClass('mobile-scrollable-fields')
      } else {
        $fieldsContainer.removeClass('mobile-scrollable-fields')
      }
    }, {
      root: $fieldsContainer[0],
      threshold: 0.95
    }).observe($lastField[0]);
  }

  function triggerUserLink() {
    // field's z-index is higher but we need to handle the click on it as click on the link
    $(this).find('.search-card__link')[0].click()
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.UserCard, { perElement: true });
