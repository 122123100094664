export function getCookie(name) {
  let value = '; ' + decodeURIComponent(document.cookie);
  let parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift();
  }
};

export function setCookie(name, value, expires = null) {
  if (expires) {
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
  } else {
    document.cookie = `${name}=${value}; path=/`;
  }
}

export function removeCookie(name) {
  document.cookie = `${name}=; expires=0; Max-Age=0; path=/`;
}
