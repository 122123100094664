LemonFrog.CheckboxAreaComponent = (function () {
  const moduleName = "CheckboxAreaComponent";
  const moduleRef = `[data-module=${moduleName}]`;
  const inputRef = `${moduleRef} input`;
  const checkboxComponentRef = `[data-module=CheckboxComponent]`;

  function init() {
    $(document).on('change', inputRef, function () {
      $(moduleRef).each(function () {
        const inputChecked = $(this).find('input').prop('checked');

        $(this).toggleClass('checkbox-area--checked', inputChecked);
      })
    });

    $(document).on('click', moduleRef, function () {
      $(this).find(checkboxComponentRef).click()
    });
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.CheckboxAreaComponent);
