LemonFrog.DocumentUploadForm = (function() {
  const moduleName = "DocumentUploadForm";
  const form = ".upload-form-component__form";
  const inputElement = ".upload-form-component__file--input";
  const dropZone = ".upload-form-component__file";
  const submitBtn = ".upload-form-component__submit";
  const uploadHint = ".upload-form-component__file--hint"
  const uploadError = ".upload-form-component__file--error"
  const uploadState = ".upload-form-component__file--upload";
  const uploadedState = ".upload-form-component__file--uploaded";
  const uploadingState = ".upload-form-component__file--uploading";
  const fileNameField = ".upload-form-component__file--name";
  const removeFileBtn = ".upload-form-component__file--remove";
  const inputTitle = ".upload-form-component__title";
  const selectItems = ".allowed-list__item";

  function init() {
    initEventListeners();
    prepareInitialForm();
  }

  function prepareInitialForm() {
    // Form state when user first time see page, refreshing it, etc.
    $(inputTitle).hide();
    $(dropZone).hide();
    $(submitBtn).hide();
    showActionsAfterSelect();
  }

  function initEventListeners() {
    // Switching the borders for input
    $(dropZone).on("dragover", onDragOver);

    ["dragend", "dragleave"].forEach(event => {
      $(dropZone).on(event, function() {
        switchClasses(false);
      });
    })

    // Upload document by drag and drop
    $(dropZone).on("drop", e => { onFileSelected(true, e) });
    $(inputElement).on("change", e => { onFileSelected(false, e) })

    // Upload document by click and browse
    $(uploadState).on("click", e => {
      $(inputElement)[0].click();
    });

    $(inputElement).parsley().on('field:error', () => {
      applyUploadState()
      $(dropZone).removeClass("border-color-primary border-solid").addClass("border-dashed border-color-red error");
    });

    $(form).submit(applyUploadingState)

    $(removeFileBtn).on("click", removeDocument);
  }

  function onDragOver(event) {
    event.preventDefault();
    switchClasses(true);
  }

  function onFileSelected(drop, event) {
    event.preventDefault();

    let file = null;
    // In case of drag and drop
    if (drop) {
      // File stored in a dataTransfer
      file = event.originalEvent.dataTransfer.files[0];
      $(inputElement)[0].files = event.originalEvent.dataTransfer.files;
    } else {
      // But in case of click and browse, file stored in input
      file = $(inputElement)[0].files[0];
    }

    if(file && file.type == "application/pdf") {
      $(submitBtn).show();
    }else {
      applyErrorState();
      return
    }

    switchClasses(false);
    applyUploadedState(file.name);
  }

  function showActionsAfterSelect() {
    $(selectItems).each((index, elem) => {
      $(elem).on("click", function() {
        $(dropZone).show();

        // Hide and show title for document with edited title
        if($(elem)[0].dataset.withTitle == "true") {
          showInputTitle();
        } else {
          hideInputTitle();
        }
      });
    });
  }

  function switchClasses(solid) {
    if(solid) {
      $(dropZone).removeClass("border-dashed border-color-gray-4");
      $(dropZone).addClass("border-solid border-color-primary");
    } else {
      $(dropZone).removeClass("border-solid border-color-primary");
      $(dropZone).addClass("border-dashed border-color-gray-4");
    }
  }

  function hideInputTitle() {
    $(".js-document-title")[0].dataset.parsleyRequired = false
    $(inputTitle).hide();
  }

  function showInputTitle() {
    $(".js-document-title")[0].dataset.parsleyRequired = true
    $(inputTitle).show();
  }

  function applyUploadedState(fileName) {
    // When file allready uploaded
    applyInitialState()
    $(fileNameField).text(fileName);
    $(dropZone).removeClass("border-dashed").addClass("border-solid");
    $(uploadedState).show();
    $(submitBtn).show();
  }

  function applyUploadState() {
    // When file is not uploaded but drop zone is visible
    applyInitialState()
    $(uploadHint).show();
    $(uploadState).show();
    switchClasses(false);
  }

  function applyErrorState() {
    // When something went wrong
    applyInitialState()
    $(dropZone).removeClass("border-color-primary border-solid").addClass("border-dashed border-color-red error");
    $(uploadError).show();
    $(uploadState).show();
  }

  function applyUploadingState() {
    // When file is uploading
    applyInitialState();
    $(dropZone).addClass("uploading border-solid").removeClass("border-dashed");
    $(uploadingState).show();
  }

  function applyInitialState() {
    // Remove all styles, elements, text etc. from drop zone
    $(dropZone).removeClass("error uploading border-color-primary border-solid border-color-red").addClass("border-dashed border-color-gray-4");
    $(uploadState).hide();
    $(uploadedState).hide();
    $(uploadingState).hide();
    $(uploadHint).hide();
    $(uploadError).hide();
    $(submitBtn).hide();
  }

  function removeDocument() {
    $(inputElement)[0].value = "";
    applyUploadState();
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.DocumentUploadForm);
