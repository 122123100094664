LemonFrog.MultiSelect = (function() {
  const moduleName = 'MultiSelect';
  const wrapperSelector = `[data-module='${moduleName}']`
  const allItemsSelect = '.js-all-items'

  function init() {
    $(document).on('input', allItemsSelect, allItemsToggle)
    $(document).on('input', '.js-multiselect-item', updateDisplayedValues)
    $(document).on('input', '.js-multiselect-item', updateClearIconVisibility)
    $(document).on('click', '.js-clear-icon', clearSelect)
  }

  function allItemsToggle() {
    let parent = $(this).closest(wrapperSelector)
    let [checkboxes, allSelected] = getCheckboxesWithStatus(parent)

    checkboxes.prop('checked', !allSelected)
    checkboxes.trigger('change').trigger('input');
  }

  function updateDisplayedValues() {
    let parent = $(this).closest(wrapperSelector)
    let [checkboxes, _] = getCheckboxesWithStatus(parent)
    let placeholder = parent.find('.multiselect-component__placeholder')

    updateGeneralItemStatus(parent)
    placeholder.val(getSelectedValuesContent(parent)).trigger('change');
    updateClassesForChecked(checkboxes)
  }

  function clearSelect() {
    let parent = $(this).closest(wrapperSelector)
    let [checkboxes, _] = getCheckboxesWithStatus(parent)
    let placeholder = parent.find('.multiselect-component__placeholder')

    checkboxes.prop('checked', false)
    updateGeneralItemStatus(parent)
    checkboxes.trigger('change').trigger('input')
    placeholder.val(getSelectedValuesContent(parent)).trigger('change');
    updateClassesForChecked(checkboxes)
  }

  function updateClassesForChecked(checkboxes) {
    checkboxes.each((_, checkbox) => {
      let item = $(checkbox).closest('.multiselect-component__item')

      if ($(checkbox).is(':checked')) {
        item.addClass("checked")
      } else {
        item.removeClass("checked")
      }
    })
  }

  function updateGeneralItemStatus(wrapper) {
    let [_, allSelected] = getCheckboxesWithStatus(wrapper)

    wrapper.find(allItemsSelect).prop('checked', allSelected)
  }

  function getSelectedValuesContent(wrapper) {
    return wrapper.find('input[type="checkbox"]:checked')
                  .not(allItemsSelect)
                  .map((_, elem) => $(elem).closest('.checkbox__inner').find('.checkbox__text').get())
                  .map((_, elem) => $(elem).text().trim())
                  .toArray()
                  .join(', ')
  }

  function getCheckboxesWithStatus(wrapper) {
    let checkboxes = wrapper.find('input[type="checkbox"]').not(allItemsSelect)
    // every(elem => elem) returs true only if all checkboxes are checked
    // and false if at least 1 is not
    let allSelected = checkboxes.map((_, elem) => $(elem).prop('checked')).toArray().every(elem => elem)

    return [checkboxes, allSelected]
  }

  function updateClearIconVisibility() {
    let wrapper = $(this).closest(wrapperSelector)
    let isSomethingChecked = wrapper.find('input[type="checkbox"]:checked').length > 0

    wrapper.find('.js-clear-icon').toggleClass('visible', isSomethingChecked)
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.MultiSelect);
