LemonFrog.Timer = (function() {
  const moduleName = 'Timer'

  function hours(totalSeconds) {
    return Math.floor(totalSeconds / 60 / 60).toString().padStart(2, '0');
  }

  function minutes(totalSeconds) {
    return Math.floor(totalSeconds / 60 % 60).toString().padStart(2, '0');
  }

  function seconds(totalSeconds) {
    return Math.floor(totalSeconds % 60).toString().padStart(2, '0');
  }

  function updateTimer(timerNode, secondsLeft) {
    $(timerNode).html([hours(secondsLeft), minutes(secondsLeft), seconds(secondsLeft)].join(' : '));
  }

  function init(timerNode) {
    let secondsLeft = Number($(timerNode).data('seconds-left'));

    if (!(secondsLeft && secondsLeft > 0)) return;

    updateTimer(timerNode, secondsLeft);

    const countdown = setInterval(function() {
      updateTimer(timerNode, --secondsLeft);

      if (secondsLeft === 0) clearInterval(countdown);
    }, 1000);
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.Timer, { perElement: true });
