$(document).ready(function () {
  $('.js-registration-form .js-password-indicator-field').keyup(function (event) {
    let $target = $(event.target);
    checkStrength($target, $target.val());
  });

  function getUniqueChars (password) {
    return password.split('').filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });
  };

  function checkStrength (element, password) {
    let $parent = element.parent();
    let strength = 0;
    let classesIndicatingPasswordStrength = 'weak good strong';
    let filteredPassword = getUniqueChars(password);

    let $passwordStrongMark = $parent.find('.js-password-strong');
    let $passwordGoodMark = $parent.find('.js-password-good');
    let $passwordWeakMark = $parent.find('.js-password-weak');

    if (password.length < 1) {
      $passwordStrongMark.removeClass(classesIndicatingPasswordStrength);
      $passwordGoodMark.removeClass(classesIndicatingPasswordStrength);
      $passwordWeakMark.removeClass(classesIndicatingPasswordStrength);
    }

    if (password.length > 7 && filteredPassword.length > 7) {
      strength += 1;
    }

    if (password.length > 15 && filteredPassword.length > 7) {
      strength += 1;
    }

    if (password.length > 20 && filteredPassword.length > 7) {
      strength += 1;
    }

    if (password.match(/([a-z][^a-z]*[A-Z])|([A-Z][^A-Z]*[a-z])/)) {
      strength += 1;
    }

    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
      strength += 1;
    }

    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strength += 1;
    }

    if (password.match(/([^!,%&@#$^*?_~]*[!,%&@#$^*?_~][^!,%&@#$^*?_~]*[!,%&@#$^*?_~])/)) {
      strength += 1;
    }

    if (strength < 2) {
      if (password.length < 1) {
        $passwordStrongMark.removeClass(classesIndicatingPasswordStrength);
        $passwordGoodMark.removeClass(classesIndicatingPasswordStrength);
        $passwordWeakMark.removeClass(classesIndicatingPasswordStrength);
      } else {
        $passwordStrongMark.removeClass(classesIndicatingPasswordStrength);
        $passwordGoodMark.removeClass(classesIndicatingPasswordStrength);
        $passwordWeakMark.removeClass(classesIndicatingPasswordStrength).addClass('weak');
      }
    } else if (strength === 2) {
      $passwordStrongMark.removeClass(classesIndicatingPasswordStrength);
      $passwordWeakMark.removeClass(classesIndicatingPasswordStrength).addClass('good');
      $passwordGoodMark.removeClass(classesIndicatingPasswordStrength).addClass('good');
    } else {
      $passwordWeakMark.addClass('strong');
      $passwordGoodMark.addClass('strong');
      $passwordStrongMark.addClass('strong');
    }
  }
});
