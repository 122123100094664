LemonFrog.PendingRequests = (function() {
  const moduleName = "PendingRequests";

  function init() {
    $(".js-hidden-pending").hide();
    $(".js-show-less-pending-btn").hide();
    $(".js-show-more-pending-btn").click(onShowMorePending);
    $(".js-show-less-pending-btn").click(onShowLessPending);
  }

  function onShowMorePending() {
    $(".js-show-more-pending-btn").hide();
    $(".js-show-less-pending-btn").show();
    $(".js-hidden-pending").slideDown();
  }

  function onShowLessPending() {
    $(".js-show-less-pending-btn").hide();
    $(".js-show-more-pending-btn").show();
    $(".js-hidden-pending").slideUp();
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.PendingRequests);
