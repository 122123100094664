import consumer from './consumer';

export default consumer.subscriptions.create('TypingIndicatorsChannel', {
  received: function(data) {
    LemonFrog.TypingIndicator.startIndicatorTimer(data['conversation_id']);
  },

  sendMessage: function(conversationId) {
    this.perform('send_message', { conversation_id: conversationId });
  }
});
