import { getCookie, removeCookie } from '/helpers/cookies';

LemonFrog.GoogleAnalyticsBackendEvents = (function () {
  function init() {
    const cookie = getCookie("ga_backend_events");
    const parsedCookie = cookie ? JSON.parse(cookie) : [];
    removeCookie("ga_backend_events");
    window.dataLayer = window.dataLayer || [];

    parsedCookie.forEach(event => dataLayer.push(event));
  }

  return {
    init: init
  };
}());

$(function() {
  LemonFrog.GoogleAnalyticsBackendEvents.init();
});
