LemonFrog.TransparentPricingStep = (function () {
  let moduleName = 'TransparentPricingStep';

  function init() {
    $('.js-experiment-transparent-pricing-btn').click(onPricingBtnClick);
  }

  function onPricingBtnClick(e) {
    let $prices = $('.js-experiment-transparent-pricing-prices');

    if ($prices.is(":visible")) {
      $(e.currentTarget).find('svg').css({'transform' : 'rotate(270deg)'});
    } else {
      $(e.currentTarget).find('svg').css({'transform' : 'rotate(90deg)'});
    }

    $('.js-experiment-transparent-pricing-btn-opened-text').toggle();
    $('.js-experiment-transparent-pricing-btn-closed-text').toggle();
    $prices.slideToggle(500)
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.TransparentPricingStep);
