import GuillotineHelper from '/helpers/guillotine';
let Hammer = require("hammerjs");

LemonFrog.AttachmentsEditorComponent = (function () {
  const moduleName = 'AttachmentsEditorComponent';
  const moduleRef = `[data-module="${moduleName}"]`;
  const actionsRef = '.action-buttons'
  let prevPinchScale = 0;

  function init() {
    waitForImagesToLoad()
    initGuillotineHelper()
  }

  function waitForImagesToLoad() {
    $(moduleRef).find(actionsRef).hide()

    $(moduleRef).each((_, elem) => {
      let $this = $(elem)
      let $imageNode = $this.find('img')

      if ($imageNode[0].complete) {
        $this.find(actionsRef).show()
      } else {
        $imageNode.on('load', function() {
          $this.find(actionsRef).show()
        })
      }
    })
  }

  function initGuillotineHelper() {
    $(moduleRef).each((_, elem) => {
      let $this = $(elem)
      let $imageNode = $this.find('img')
      let guillotineHelper = new GuillotineHelper()
      let cropData = prepareCropData($this)

      // Init hammer with pinch event enabled
      let hammer = new Hammer($imageNode.parent()[0])
      hammer.get('pinch').set({enable: true})
      // Add pinch handlers to hammer
      hammer.on('pinch', handlePinch.bind(guillotineHelper))
      hammer.on('pinchstart', onPinchStart)

      // Init guillotine
      guillotineHelper.init($imageNode, cropData, updateFormValues);

      // updateFormValues() called on 'onchange' event
      // we need to call it here to make sure that the form data will be correct
      // even if 'onchange' has not been triggered by user
      updateFormValues.call($imageNode, guillotineHelper.getData())

      // Add guillotine handlers to image
      $this.find('.photo-editor__button.zoom-out')
        .on('click', handleCropClick.bind(guillotineHelper, 'zoomOut'));

      $this.find('.photo-editor__button.zoom-in')
        .on('click', handleCropClick.bind(guillotineHelper, 'zoomIn'));

      $this.find('.photo-editor__button.rotate')
        .on('click', handleCropClick.bind(guillotineHelper, 'rotateRight'));
    })
  }

  function handleCropClick(eName) {
    // same as -> guillotineHelper.zoomIn()
    eval(`this.${eName}()`)
  }

  function onPinchStart(ev) {
    prevPinchScale = ev.scale;
  }

  function handlePinch(ev) {
    if (this.zoomToFactor(ev.scale/prevPinchScale)) {
      prevPinchScale = ev.scale;
    }
  }

  function updateFormValues(data, _) {
    let $form = $(this).closest(moduleRef).find('form[id*=attachment-form]')

    let formData = {
      crop_x: data.x,
      crop_y: data.y,
      angle: data.angle,
      crop_h: data.h,
      crop_w: data.w,
      scale: data.scale
    }

    fillForm($form, formData)
  }

  function fillForm($form, data) {
    for (let key in data) {
      let $input = $form.find(`input[name='attachment[${key}]']`)
      $input.prop('value', data[key])
    }
  }

  function prepareCropData($moduleSel) {
    let data = {}
    // Get all form attributes with their values
    $moduleSel.find('form').serializeArray().forEach(({ name, value }) => {
      // Do not take into account auth_token and redirect_path values
      if (name.includes('token') || name.includes('redirect')) return;
      // Select only attribute name, without namespace i.e: attachment[crop] => crop
      let [startIdx, endIdx] = [name.indexOf("[") + 1, name.indexOf("]")]
      name = name.slice(startIdx, endIdx)

      data[name] = Number(value)
    })

    return prepareGuillotineData(data)
  }

  function prepareGuillotineData({
    crop_x: x,
    crop_y: y,
    angle,
    crop_h,
    crop_w,
    photo_width,
    photo_height,
    scale
  }) {
    const cropData = { angle, x, y, scale };
    const defaultSize = 640;
    cropData.minScale = defaultSize / Math.min(photo_height, photo_width);

    if (!(crop_h || crop_w)) {
      cropData.scale = cropData.minScale;

      if (photo_height > photo_width) {
        cropData.y = (photo_height - photo_width) / 2 * cropData.scale;
      } else {
        cropData.x = (photo_width - photo_height) / 2 * cropData.scale;
      }
    }

    return cropData;
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.AttachmentsEditorComponent);
