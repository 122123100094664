LemonFrog.Dismissing = (function () {
  const moduleName = 'Dismissing';

  function init () {
    $('#main_logo_link').click(function (e) {
      e.stopPropagation();
      e.preventDefault();
      LemonFrog.Dialog.showDialog('#profile-dismissing-dialog');
    });
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.Dismissing);
