LemonFrog.LanguageSelector = (function() {
  const moduleName = 'LanguageSelector',
    mobilelanguageMenuSel = '.js-mobile-language-selector-menu';

  function init() {
    $(document).on('click', '.js-mobile-language-selector-toggle', toggleMobileLanguageMenu);
    $(window).on('resize', handleMobileMenuDisplay);
  }

  function toggleMobileLanguageMenu() {
    $(document).find(mobilelanguageMenuSel).toggleClass('hidden');
    $('html').toggleClass('new-styling overflow-hidden');
  }

  function handleMobileMenuDisplay() {
    const menuIsVisible = $(mobilelanguageMenuSel).is(':visible'),
          pageOverflowHidden = $('html').hasClass('new-styling overflow-hidden');

    if (pageOverflowHidden && !menuIsVisible) {
      $('html').removeClass('new-styling overflow-hidden');
    }
    if (!pageOverflowHidden && menuIsVisible) {
      $('html').addClass('new-styling overflow-hidden');
    }
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.LanguageSelector);
