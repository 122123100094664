import consumer from "./consumer";

export default consumer.subscriptions.create('MessagesChannel', {
  received: function(data) {
    LemonFrog.Conversations.updateMessageCounters(
      data['navMessagesPartial'],
      data['unreadMessagesCounterPartial'],
      data['mobileMenuPartial'],
      data['mobileDashboardPartial'],
      data['mobileNotificationsPartial']
    );

    if (LemonFrog.ConversationInterfaceUsersList &&
        (LemonFrog.Conversations.currentTab() != 'sent' || data['canAppearInSentTab'] === true)) {
      LemonFrog.ConversationInterfaceUsersList.updateConversationPreview(
        data['conversationId'],
        data['conversationPreviewPartial']
      );
    }

    if (LemonFrog.Conversations.currentConversationId() === data['conversationId']) {
      LemonFrog.ConversationInterfaceDialogBox.appendMessage(data['message']);
      LemonFrog.UnreadMessages.clear(data['conversationId']);
    }

    LemonFrog.TypingIndicator.hideIndicator(data['conversationId']);
  },
});
