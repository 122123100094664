LemonFrog.Notification = (function() {
  const moduleName = 'Notification';
  const notification = '.notifications-component';
  const progressBar = '.js-notification-progress-bar';
  const dismissNotificationTime = 10000;

  function init() {
    initShowAnimation();
    initNotificationDismiss();
    initTimerToHideNotification();
    initProgressBar();
  }

  function initShowAnimation() {
    $(notification).each((_, notif) => {
      if (notif.classList.contains('js-permanent-notification')) return;

      $(notif).animate({
        top: 0,
        opacity: 1,
      }, 300)
    })
  }

  function initNotificationDismiss() {
    $('.js-notification-dismiss').click(function(event) {
      event.preventDefault();
      let forRemoving = $(event.currentTarget).parent()

      dismissNotification(forRemoving)
    });
  }

  function initTimerToHideNotification() {
    $(notification).each((index, elem) => {
      if(!$(notification)[index].classList.contains('js-permanent-notification')) {

        setTimeout(function() {
          dismissNotification(elem)
        }, dismissNotificationTime)
      }
    })
  }

  function initProgressBar() {
    $(progressBar).each((index, bar) => {
      $(bar).animate({
        top: '100%'
      }, dismissNotificationTime + 200, 'linear')
    })
  }

  function dismissNotification(target) {
    $(target).animate({
      top: 100,
      opacity: 0,
    }, 200, function() {
      $(target).remove()
    })
  }

  return {
    init: init,
    moduleName: moduleName
  }
}())

LemonFrog.initModule(LemonFrog.Notification);

$(document).ready(function() {
  const observer = new MutationObserver(function(mutations) {
    const isAdded = mutations.filter((mut) => mut.addedNodes.length).length;

    if (isAdded) LemonFrog.Notification.init();
  })

  if ($('.js-alert-container').length) {
    observer.observe(
      document.querySelector('.js-alert-container'),
      { childList: true }
    );
  }
});
