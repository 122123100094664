LemonFrog.BtnGroupSelect = (function() {
  const moduleName = 'BtnGroupSelect';
  const wrapper = '.btn-group-select';
  const option = '.btn-group-select__option';
  const actualSelect = '.btn-group-select__select';

  function init() {
    $(option).on('click', selectOptionToggle);
  }

  function selectOptionToggle() {
    if ($(this).hasClass('selected')) return

    let parentWrapper = $(this).closest(wrapper);
    let selectedOption = parentWrapper.find('.btn-group-select__option.selected');

    bindSelectedAttrToInput($(this))

    $(selectedOption).removeClass('selected');
    $(this).addClass('selected');
  }

  function bindSelectedAttrToInput(selected) {
    let parentWrapper = selected.closest(wrapper);
    let selectInput = parentWrapper.find(actualSelect);

    selectInput.val(selected.attr("select_value"));
    selectInput.trigger('change');
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.BtnGroupSelect);
