LemonFrog.DoubleRangeComponent = (function() {
  const moduleName = 'DoubleRangeComponent';
  const wrapper = '.double-range';
  const fromSliderSelector = '.double-range__input.from';
  const toSliderSelector = '.double-range__input.to';
  const thumbSize = 18;

  function init() {
    $(wrapper).each(function() {
      let fromSlider = $(this).find(fromSliderSelector);
      let toSlider = $(this).find(toSliderSelector);

      fillSlider($(this));
      updateDisplayedValue($(this));
      setMinMax($(this));

      $(fromSlider).on('input change', controlFromSlider);
      $(toSlider).on('input change', controlToSlider);
    })
  }

  function controlFromSlider() {
    let [slider, parentWrapper, values] = beforeEachListener($(this))

    if (values.from > values.to) {
      slider.val(values.to - 1);
    }

    afterEachListener(parentWrapper)
  }

  function controlToSlider() {
    let [slider, parentWrapper, values] = beforeEachListener($(this))

    if (values.to < values.from) {
      slider.val(values.from + 1);
    }

    afterEachListener(parentWrapper)
  }

  function beforeEachListener(element) {
    let parentWrapper = element.closest(wrapper);
    let values = getParsedValues(parentWrapper);

    return [element, parentWrapper, values]
  }

  function afterEachListener(targetWrapper) {
    fillSlider(targetWrapper);
    updateHolderValues(targetWrapper);
    updateDisplayedValue(targetWrapper);
  }

  function fillSlider(wrapper) {
    let values = getParsedValues(wrapper);
    let slider = wrapper.find(toSliderSelector);
    let rangeDistance = values.maxTo - values.minFrom;
    let fromPosition = values.from - values.minFrom;
    let toPosition = values.to - values.minFrom;

    slider.css('background', `linear-gradient(
      to right,
      transparent 0%,
      transparent calc(${fromPosition/rangeDistance*100}% + ${thumbSize * ((rangeDistance - fromPosition)/rangeDistance)}px),
      #707070 ${fromPosition/rangeDistance*100}%,
      #707070 ${toPosition/rangeDistance*100}%,
      transparent ${toPosition/rangeDistance*100}%,
      transparent 100%)`
    );
  }

  function updateHolderValues(wrapper) {
    let values = getParsedValues(wrapper);
    let fromValueHolder = wrapper.find('.from-value-holder');
    let toValueHolder = wrapper.find('.to-value-holder');

    fromValueHolder.val(values.from);
    toValueHolder.val(values.to);
  }

  function updateDisplayedValue(wrapper) {
    let placeholder = wrapper.find('.displayed-value')
    let values = getParsedValues(wrapper)

    placeholder.text(`${values.from} - ${values.to}`);
  }

  function getParsedValues(wrapper) {
    let from = wrapper.find(fromSliderSelector);
    let to = wrapper.find(toSliderSelector);

    return {
      // Sliders
      from: parseInt(from.val()),
      to: parseInt(to.val()),
      // Values from
      minFrom: parseInt(from.attr('min')),
      maxFrom: parseInt(from.attr('max')),
      // Values to
      minTo: parseInt(to.attr('min')),
      maxTo: parseInt(to.attr('max')),
    }
  }

  function setMinMax(wrapper) {
    let from = wrapper.find(fromSliderSelector);
    let to = wrapper.find(toSliderSelector);
    let values = getParsedValues(wrapper);

    $(from).attr('max', values.maxTo - 1);
    $(to).attr('min', values.minFrom + 1);
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.DoubleRangeComponent);
