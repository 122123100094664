function hyphenToCamelCase (input) {
  return input.toLowerCase().replace(/-(.)/g, function (match, group1) {
    return group1.toUpperCase();
  });
}

export function mapHTMLAttributesToObject (selector, prefix, hyphen) {
  let obj = {};

  $($(selector)[0].attributes).each(function () {
    let attributeName = this.nodeName;
    let prefixed = attributeName.lastIndexOf(prefix, 0) === 0;

    if (prefixed) {
      let attributeNameWithoutPrefix = attributeName.replace(prefix, '');

      if (hyphen) {
        attributeNameWithoutPrefix = hyphenToCamelCase(attributeNameWithoutPrefix);
      }

      obj[attributeNameWithoutPrefix] = this.nodeValue;
    }
  });

  return obj;
}
