LemonFrog.IntersectionVisibility = (function () {
  const moduleName = 'IntersectionVisibility';

  function init () {
    intersectionVisibilityHandler();
  }

  function intersectionVisibilityHandler() {
    let observer = new IntersectionObserver(function(entries) {
      if (entries[0].isIntersecting === true)
        $('.js-intersecting').fadeOut('slow');
      else
        $('.js-intersecting').fadeIn('slow');
    }, { threshold: [0] });

    observer.observe(document.querySelector('.js-observed'));
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.IntersectionVisibility);
