import { getCookie } from '/helpers/cookies';

$(function () {
  $('.footer-logos a').on('mousedown', function () {
    let locale = getCookie('locale');

    if (locale !== undefined) {
      let link = $(this);
      if (!link[0].dataset.partner) {
        let splitLink = link[0].href.split('/');

        splitLink[3] = locale;
        link[0].href = splitLink.join('/');
      }
    }
  });
});

function setFooterPosition () {
  let footer = $('.js-footer-fixed');
  let bodyHeight = $('body').height() + footer.height();
  let windowHeight = window.innerHeight
    ? window.innerHeight
    : $(window).height();

  let shouldFixFooter = bodyHeight < windowHeight;

  if (shouldFixFooter) {
    footer.css('position', 'fixed').css({
      bottom: 0,
      left: 0
    });
  } else {
    footer.css('position', 'absolute').css({
      bottom: '',
      left: 0
    });
  }
}

function onBodyHeightChange (callback) {
  let body = document.body;
  let lastHeight = body.clientHeight;
  let newHeight;
  (function run () {
    newHeight = body.clientHeight;
    if (lastHeight !== newHeight) {
      callback();
    }
    lastHeight = newHeight;
    if (body.onElementHeightChangeTimer) {
      clearTimeout(body.onElementHeightChangeTimer);
    }
    body.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}

$(document).ready(function () {
  setFooterPosition();
  onBodyHeightChange(setFooterPosition);
});

$(window).on('load', function () {
  setFooterPosition();
});

$(window).resize(function () {
  setFooterPosition();
});
