LemonFrog.Confetti = (function() {
  const moduleName = 'Confetti';

  function init($canvas) {
    const canvas = $canvas[0],
      context = canvas.getContext("2d"),
      particles = [],
      confettiContainer = $canvas.parent('.confetti-container'),
      canvasWidth = confettiContainer.width(),
      canvasHeight = confettiContainer.height() + footerMarginFix(confettiContainer),
      maxConfettis = calculateConfettiNumber(canvasWidth),
      [sizeFrom, sizeTo] = calculateConfettiSize(canvasWidth),
      tiltСoeff = calculateTiltСoeff(canvasWidth),
      confettiColor = $canvas.css('color').replace(')', ', 0.2)');

    // fill the confetti array
    for (let i = 0; i < maxConfettis; i++) {
      particles.push(
        new confettiParticle(canvasWidth,canvasHeight, context, maxConfettis, sizeFrom, sizeTo, confettiColor)
      );
    }

    // start animation
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    drawConfettiAnimation(canvasWidth,canvasHeight, context, particles, maxConfettis, tiltСoeff);
  }

  function footerMarginFix(confettiContainer) {
    const footer = confettiContainer.next();
    if (!footer.hasClass('js-footer-fixed')) return 0;

    const containerBottom = (confettiContainer.offset().top + confettiContainer.height()),
      windowHeight = window.innerHeight ? window.innerHeight : $(window).height();
    if ((containerBottom + footer.height()) >= windowHeight) return 0;

    return windowHeight - containerBottom - footer.height();
  }

  function calculateConfettiNumber(canvasWidth) {
    if (canvasWidth < 768) {
      return 60;
    } else if (canvasWidth < 1200) {
      return 100;
    } else {
      return 120;
    }
  }

  function calculateConfettiSize(canvasWidth) {
    if (canvasWidth < 768) {
      return [8, 16];
    } else {
      return [11, 33];
    }
  }

  function calculateTiltСoeff(canvasWidth) {
    if (canvasWidth < 768) {
      return 10;
    } else {
      return 15;
    }
  }

  function randomFromTo(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }

  function confettiParticle(canvasWidth,canvasHeight, context, maxConfettis, sizeFrom, sizeTo, confettiColor) {
    this.x = Math.random() * canvasWidth;
    // the level above the viewport from where the confetti starts falling
    // affects overall animation duration and confetti density
    const startHeight = canvasHeight/2;
    this.y = Math.random() * startHeight - startHeight;

    this.r = randomFromTo(sizeFrom, sizeTo); // radius (size of the particle)
    this.d = Math.random() * maxConfettis + sizeTo;
    this.color = confettiColor;
    this.tilt = Math.floor(Math.random() * sizeTo) - sizeFrom;
    this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
    this.tiltAngle = 0;

    this.draw = function() {
      context.beginPath();
      context.lineWidth = this.r / 2;
      context.strokeStyle = this.color;
      context.moveTo(this.x + this.tilt + this.r / 3, this.y);
      context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
      return context.stroke();
    };
  }

  function drawConfettiAnimation(canvasWidth,canvasHeight, context, particles, maxConfettis, tiltСoeff) {
    const results = [],
      animationFPS = 40; // larger number for faster and smoother animation

    context.clearRect(0, 0, canvasWidth, canvasHeight);

    // stop animation when all confetti are out of the screen
    if (!particles.filter(particle => (particle.y - particle.r) <= canvasHeight).length) return;

    // draw next frame of animation
    setTimeout(() => {
      requestAnimationFrame(() => drawConfettiAnimation(canvasWidth,canvasHeight, context, particles, maxConfettis, tiltСoeff));
    }, 1000 / animationFPS);

    for (let i = 0; i < maxConfettis; i++) {
      results.push(particles[i].draw());
    }

    let particle = {};
    for (let i = 0; i < maxConfettis; i++) {
      particle = particles[i];

      particle.tiltAngle += particle.tiltAngleIncremental;
      particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2; // the larger number the faster the confetti falls
      particle.tilt = Math.sin(particle.tiltAngle - i / 3) * tiltСoeff; // the larger number the wider the confetti swing range
    }

    return results;
  }

  return {
    init: init,
    moduleName: moduleName
  }
}());

LemonFrog.initModule(LemonFrog.Confetti, { perElement: true });
