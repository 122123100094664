import { setCookie } from '/helpers/cookies';

LemonFrog.LimitedDiscountBanner = (function() {
  const moduleName = 'LimitedDiscountBanner';

  function init() {
    const fixedMobileBanner = $('.limit-discount__mobile-banner');

    fixedMobileBanner.on('click', '.js-dismiss-banner', function () {
      setCookie('dismissed-limited-time-discount-banner', true);
      $(this).parents('.limit-discount__mobile-banner').fadeOut(200);
    });

    fixedMobileBanner.on('click', '.js-payment-trigger-link', function () {
      setCookie('dismissed-limited-time-discount-banner', true);
    });
  }

  return {
    moduleName: moduleName,
    init: init,
  }
}());

LemonFrog.initModule(LemonFrog.LimitedDiscountBanner);
