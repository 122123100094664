function scrollToError() {
  const errorTop = $(`.has-error:visible`).first().offset().top,
    headerHeight = $('.wizard-fixed-header').first().outerHeight() || 0,
    extraMargin = 10; // to make it not to stick to the very top
  window.scrollTo({top: errorTop - headerHeight - extraMargin, behavior: 'smooth'});
}

$(document).ready(function () {
  const onboardingForm = $('.wizard #profile_form');
  if(onboardingForm.length) {
    onboardingForm.parsley().on('form:error', scrollToError);
  }
});
