LemonFrog.HidingProfileSurvey = (function () {
  const moduleName = "HidingProfileSurvey";
  let $form;

  function init () {
    $form = $(`[data-module="${moduleName}"]`);

    initHidingProfileReasonDialog();
    initFoundHelpDialog();
    initOtherReasonDialog();
  }

  function initHidingProfileReasonDialog() {
    $(".hiding-profile-reason-dialog").on('dialog.show', resetOtherReasonDialog);

    $(".js-hiding-reason-radio").click(function () {
      $('input', this).prop('checked', true);

      switch ($('input', this).val()) {
      case 'found_help':
        LemonFrog.Dialog.dismissDialog('.hiding-profile-reason-dialog', function() {
          LemonFrog.Dialog.showDialog('.found-help-dialog');
        });
        break;
      case 'other_reason':
        LemonFrog.Dialog.dismissDialog('.hiding-profile-reason-dialog', function() {
          LemonFrog.Dialog.showDialog('.other-reason-feedback-dialog');
        });
        break;
      case 'membership_too_expensive':
      case 'enough_applications':
        $form.submit();
        break;
      }
    });
  }

  function initFoundHelpDialog() {
    $(document).on('click', '.js-user-select', function () {
      let userId = $(this).data('userId');
      $('.js-selected-user-id').val(userId);

      $form.submit();
    });
  }

  function initOtherReasonDialog() {
    $('input[type="radio"][name="user_feedback[variant]"]').on("change", function () {
      if( $(this).hasClass("js-show-other-reason-input")) {
        $('.js-other-reason-input').show('shift', { easing: 'easeOutCubic', shift: 8, from: "left" }, 300, function() {
          $(".js-other-reason-dialog-actions").show('shift', { easing: 'easeOutCubic', shift: 8 }, 300);
          $(this).closest(".dialog-content__body").animate({
            scrollTop: $(".js-other-reason-input").position().top
          }, 500);
          $(".js-other-reason-input").find("textarea").focus();
        });

        $('#user_feedback_additional_info')
          .prop("disabled", false)
          .attr("data-parsley-required", "true")
      } else {
        $(".js-other-reason-dialog-actions").show('shift', { easing: 'easeOutCubic', shift: 8 }, 300);
        $('.js-other-reason-input').hide('shift', { easing: 'easeOutCubic', shift: 8, from: "left" }, 300);
        $('#user_feedback_additional_info')
          .prop("disabled", true)
          .attr("data-parsley-required", "false")
      }
    })
  }

  function resetOtherReasonDialog() {
    $(".js-other-reason-dialog-actions").hide();
    $('.js-other-reason-input').hide();
    $('#user_feedback_additional_info')
      .prop("disabled", true)
      .attr("data-parsley-required", "false")
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.HidingProfileSurvey);
