export function getBreakpoint () {
  const breakpoints = {
    sm: '768',
    md: '992',
    lg: '1200'
  };

  if (checkIfBiggerThan(breakpoints.lg)) {
    return 'lg';
  } else if (checkIfBiggerThan(breakpoints.md)) {
    return 'md';
  } else if (checkIfBiggerThan(breakpoints.sm)) {
    return 'sm';
  } else {
    return 'xs';
  }

  function checkIfBiggerThan (breakpoint) {
    return window.matchMedia('screen and (min-width: ' + breakpoint + 'px)').matches;
  }
}
