LemonFrog.RegisterInfo = (function () {
  const moduleName = "RegisterInfo",
    moduleSel = `[data-module="${moduleName}"]`;

  function init() {
    refreshInfoPage();
    $(document).on('click', `${moduleSel} .wizard-check-email__info-toggle, ${moduleSel} .wizard-check-email__options-toggle`, toggleEmailActivationOptions);
  }

  function refreshInfoPage () {
    setInterval(function () {
      $.ajax({
        dataType: 'json',
        url: window.location,
        success: function (data, _status, _xhr) {
          if (data.is_activated) {
            location.reload();
          }
        }
      });
    }, 5000);
  }

  function toggleEmailActivationOptions() {
    $(`${moduleSel} .wizard-check-email__info, ${moduleSel} .wizard-check-email__options`).toggleClass('hidden');
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.RegisterInfo);
