LemonFrog.AttachmentsUploadFormComponent = (function () {
  const moduleName = 'AttachmentsUploadFormComponent';
  const moduleRef = `[data-module="${moduleName}"]`
  const inputReference = 'input[type="file"]'
  const uploadBtnRef = 'button:not(.js-disabled-button)'
  const disabledBtnRef = 'button.js-disabled-button'

  function init() {
    $(document).on('click', uploadBtnRef, handleBtnClick)
    $(document).on('change', inputReference, onInputChange)
  }

  function handleBtnClick() {
    $(this).closest(moduleRef).find(inputReference).click()
  }

  function onInputChange() {
    toggleButtons.call(this)
  }

  function toggleButtons() {
    $(this).closest(moduleRef).find(disabledBtnRef).toggle()
    $(this).closest(moduleRef).find(uploadBtnRef).toggle()
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.AttachmentsUploadFormComponent.init();
