LemonFrog.ReviewForm = (function () {
  const moduleName = "ReviewForm",
    moduleSel  = `[data-module="${moduleName}"]`;
  function init() {
    $(moduleSel).stepper({ canProceed: reviewFieldsValid });
  }

  function reviewFieldsValid(step) {
    if(step.hasClass('js-rate-step')) {
      const ratingField = $('#review_rating')
      ratingField.parsley().validate();
      return ratingField.parsley().isValid();
    }
    return true;
  }

  return {
    moduleName: moduleName,
    init: init
  }
}());

LemonFrog.initModule(LemonFrog.ReviewForm);
